import React, { useEffect, useRef, useState } from 'react'
import ProductCard from './ProductCard';
import CarouselProduct from './CarouselProduct';
import { PiShoppingCartSimple } from 'react-icons/pi';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const Carousel = ({title, products}) => {
    const carouselRef = useRef(null);

    const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    if (carouselRef.current) {
      // Add an event listener to the scrollable container to track scroll position
      carouselRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      // Remove the event listener when the component unmounts
      if (carouselRef.current) {
        carouselRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleScroll = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      setIsAtStart(container.scrollLeft === 0);
      setIsAtEnd(container.scrollLeft + container.clientWidth >= container.scrollWidth);
    }
  };

//   const handleScrollLeft = () => {
//     if (carouselRef.current) {
//       carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
//     }
//   };

//   const handleScrollRight = () => {
//     if (carouselRef.current) {
//       carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
//     }
//   };
const handleScrollLeft = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      container.scrollBy({
        left: -container.offsetWidth,
        behavior: 'smooth', // Add smooth scrolling behavior
      });
    }
  };

  const handleScrollRight = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      container.scrollBy({
        left: container.offsetWidth,
        behavior: 'smooth', // Add smooth scrolling behavior
      });
    }
  };

  return (
    <div className="flex flex-col w-full h-full gap-2 relative">
            <div className="flex flex-row w-full justify-between bg-[#FFF2E9] rounded-sm p-2">
    <div className="cursor-pointer">
                <p className={`font-satoshi font-semibold text-[20px] underline underline-offset-[14px]  decoration-[6px] text-indigo-600`}>{title}</p>
            </div>

    </div>
    <div className=" flex flex-row w-full gap-2">
    <div className="flex flex-row items-center justify-center">
        {!isAtStart &&
        <div onClick={handleScrollLeft} className="rounded-full bg-[#FFF2E9] cursor-pointer p-2.5">
              <BsArrowLeft />
            </div>}
    </div>
    <div  ref={carouselRef} className="flex flex-1 overflow-x-auto hide-scrollbar duration-500 gap-4 py-3">
            {products?.map((product) => (
              <CarouselProduct key={product?.id} product={product}/>
            ))}
  </div>
  <div className="flex flex-row items-center justify-center">
                {!isAtEnd &&
  <div onClick={handleScrollRight} className="rounded-full bg-[#FFF2E9] cursor-pointer p-2.5">
              <BsArrowRight />
            </div>}
    </div>
    </div>
    </div>
  )
}

export default Carousel