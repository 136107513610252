import { useState } from "react"
import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import SignIn from "./components/auth/SignIn"
import { selectCurrentToken } from "./features/auth/authSlice"
import { isAuthenticated } from "./utils"




export const AnonymousRoute = () => {
    const token = useSelector(selectCurrentToken)
    const [login, setLogin] = useState(true)
    // token === null ? setLogin(true):setLogin(false)
    const handleClose = () => {
        setLogin(false)
    }

    return (
        
        token !== null ? <Outlet /> : <SignIn open={login} handleClose={handleClose}/>
    )
}

// export const AuthenticatedRoute = () => {
//     const token = useSelector(selectCurrentToken)
//     const [login, setLogin] = useState(true)
//     // token === null ? setLogin(true):setLogin(false)
//     const handleClose = () => {
//         setLogin(false)
//     }

//     return (
        
//         token !== null ? <Outlet /> : <SignIn  open={login} handleClose={handleClose}/>
//     )
// }
export const AuthenticatedRoute = () => {
    const [login, setLogin] = useState(true)
    return (
        isAuthenticated() ? <Outlet /> : <SignIn show={login} setOnBoarding={setLogin} />
    )
}

export const ENDPOINT_HOMEPAGE               = '/'
export const ENDPOINT_REVAMP                 = '/rev'

export const ENDPOINT_LOGIN                  = '/login'
export const ENDPOINT_PRODUCTS               = '/products'
export const ENDPOINT_PRODUCT_DETAILS        = `${ENDPOINT_PRODUCTS}/:productID`
export const ENDPOINT_CART                   = '/cart'
export const ENDPOINT_CATEGORY               = '/category'
export const ENDPOINT_CATEGORY_PRODUCTS      = `${ENDPOINT_CATEGORY}/:categoryID`
export const ENDPOINT_PRODUCTS_SEARCH        = '/products/search'

export const ENDPOINT_ACCOUNT                = '/account'
export const ENDPOINT_CHECKOUT               = '/checkout'

export const ENDPOINT_CONTACT                = '/contact-us'
export const ENDPOINT_ORDERS                 = '/orders'
export const ENDPOINT_TRACK_ORDER            = `${ENDPOINT_ORDERS}/:orderID`



export const ENDPOINT_TERMS                  = '/terms&conditions'
export const ENDPOINT_PRIVACY_POLICY         = '/privacy-policy'




