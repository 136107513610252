import React, { useEffect, useState } from 'react'
import Product from './Product'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useLocation } from 'react-router-dom'
import ProductCard from './landing/ProductCard'


function Products({products, isLoading, displayStyle}) {
  const {pathname} = useLocation()
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);

  return (
    <div className="flex flex-col gap-2">

    <div className={`${displayStyle === 'L' && isMobile ? 'flex flex-col gap-2': 'grid grid-cols-2 md:grid-cols-5 gap-2'} w-full `}>
  
        {isLoading &&
        <>
            <Skeleton height={300} />
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300} />
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300} />
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
            <Skeleton height={300}/>
        </>
}
        <>


        
        {products?.results?.slice().reverse().map((product) => (
            <div key={product?.id} className="col-span-1 h-full w-full">
                <ProductCard
                product={product}
                />
            </div>
        ))}
        {/* :
        products?.results?.map((product) => (
          <div key={product?.id} className="col-span-1 h-full w-full">
              <ProductCard
              product={product}
              id={product?.id}
              image_urls = {product?.images_urls}
              name={product?.name}
              category={product?.category}
              old_price={product?.old_price}
              new_price={product?.new_price}
              image_url={product?.thumbnail_url} 
              />
          </div>
      ))
      } */}
        </>
        
    </div>
    </div>
  )
}

export default Products