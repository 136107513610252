import React, { useState, useEffect } from 'react'
import HomeSidebar from '../components/HomeSidebar'
import Pagination from '../components/Pagination'
import Products from '../components/Products'
import { useGetProductsQuery } from '../features/products/productsApiSlice'
import { TbLayoutGridAdd } from 'react-icons/tb';
import { FaList } from 'react-icons/fa';
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { LiaListSolid } from "react-icons/lia"
import { useSearchParams } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'



function ShopPage() {
  let currentUrlParams = new URLSearchParams(window.location.search)
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)

  const [page_size, setPage_size ] = useState(5)
  const [page, setPage ] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [prevPage, setPrevPage] = useState(1)
  const [sortBy, setSortBy] = useState('')
  const [sortByPrice, setSortByPrice] = useState('')
  const [lowPrice, setLowPrice] = useState('')
  const [highPrice, setHighPrice] = useState('')
  const [cat, setCat] = useState(currentUrlParams.has('cat') ? currentUrlParams.get('cat'):'')
  const [search, setSearch] = useState(currentUrlParams.has('q') ? currentUrlParams.get('q'):'')


  useEffect(() => {
    setCat(currentUrlParams.has('cat')? currentUrlParams.get('cat'): '')
    setSearch(currentUrlParams.has('q')? currentUrlParams.get('q'): '')
  },[urlParams])


  const [displayStyle, setDisplayStyle] = useState('L')


  const handlesortBy = (data) => {
    setSortBy(data);
  };


  const handleClearSearch = () => {
    currentUrlParams.delete('q');
    setUrlParams(currentUrlParams)
  };


  const handleCat = (data) => {
    setCat(data);
  };
  const handlesortPrice = (lp, hp) => {
    setLowPrice(lp)
    setHighPrice(hp)

  }


  const queryParams = new URLSearchParams({ page:page, page_size: page_size, lp:lowPrice, hp:highPrice, cat:cat,s:sortBy }).toString()

  
  const { data: products, isLoading } = useGetProductsQuery({page:page, date:sortBy, lp:lowPrice, hp:highPrice, cat:cat,search:search}, { skip: !page })

  useEffect(() => {

    setNextPage(products?.next_page)
    setPrevPage(products?.previous_page)
    setTotalPages(products?.total_pages)
    setCurrentPage(products?.current_page_number)
  },[products])




  const fetchNextPage = () => {
  setPage(products?.next_page)
  }

  const fetchPreviousPage = () => {
  setPage(products?.previous_page)
  }

  const fetchFirstPage = () => {
    setPage(1)    
    }

  const fetchLastPage = () => {
    setPage(products?.total_pages)

        
      }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);    

  return (
    <>
    <div className="flex flex-col w-full">
          <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
    <div className='font-satoshi container mx-auto py-12 flex flex-col md:flex-row gap-4 h-full w-full overflow-hidden px-2'>
       
    {/* sidebar */}
    <HomeSidebar showCategories={true} handlesortBy={handlesortBy} handlesortPrice={handlesortPrice} handleCat={handleCat} cat={cat}/>
<div className="flex-1 h-screen flex flex-col">
<div className="mx-2 rounded bg-slate-100 flex flex-row justify-between p-3">
  <div className=" flex flex-row items-center gap-3">
    <div onClick={() => setDisplayStyle('G')} className="cursor-pointer">
      <HiOutlineViewGridAdd size={30} className='text-gray-700 hover:text-[#FF780C]'/>
    </div>
    <div onClick={() => setDisplayStyle('L')} className="cursor-pointer">
      <LiaListSolid size={30} className='text-gray-700 hover:text-[#FF780C]'/>
    </div>
  </div>
    {/* topbar */}
    <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>
</div>
<div className={`overflow-auto hide-scrollbar h-full min-h-0 flex flex-col gap-2 px-2 pt-2 pb-6`}>
  {search &&
  <div className="flex flex-row justify-center items-center gap-3">
    <p className='text-center text-gray-700 text-[16px] md:text-[20px]'>Results for <q className='font-semibold'>{search}</q> </p>
    <div onClick={handleClearSearch} className="cursor-pointer">
      <AiFillCloseCircle size={25}/>
    </div>
  </div>
  }
  {!products?.length > 0 ?
<Products products={products} isLoading={isLoading} displayStyle={displayStyle}/>
:
<div className="flex flex-col h-96 w-full justify-center items-center">
<p className='text-center text-gray-700 text-[20px] md:text-[24px] font-semibold'>No items found</p>

</div>
}


</div>
</div>
</div>
    <RevFooter/>
    </div>
    
    </>
  )
}

export default ShopPage