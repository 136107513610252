import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SignIn from "./components/auth/SignIn";
import Payment from "./components/Payment";
import Layout from "./components/shared/Layout";
import RequireAuth from "./features/auth/RequireAuth";
import AccountPage from "./pages/AccountPage";
import CartPage from "./pages/CartPage";
import CategoryProducts from "./pages/CategoryProducts";
import CheckoutPage from "./pages/CheckoutPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SearchResultsPage from "./pages/SearchResultsPage";
import ShopPage from "./pages/ShopPage";
import SingleProductPage from "./pages/SingleProductPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import TrackOrder from "./pages/TrackOrder";
import { AuthenticatedRoute, ENDPOINT_ACCOUNT, ENDPOINT_CART, ENDPOINT_CATEGORY_PRODUCTS, ENDPOINT_CHECKOUT, ENDPOINT_CONTACT, ENDPOINT_LOGIN, ENDPOINT_PRIVACY_POLICY, ENDPOINT_PRODUCTS, ENDPOINT_PRODUCTS_SEARCH, ENDPOINT_PRODUCT_DETAILS, ENDPOINT_REVAMP, ENDPOINT_TERMS, ENDPOINT_TRACK_ORDER } from "./routes";
import ComingSoon from "./pages/ComingSoon";
import Landing from "./pages/Landing";
// import { ENDPOINT_HOMEPAGE } from "./routes";

function App() {
  return (
    <>
    <Routes>
      
      {/* <Route index element={<ComingSoon />} /> */}
      <Route index element={<Landing/>}/>
      <Route path='/' element={<Layout />}>
      {/* <Route index element={<HomePage />} /> */}



      
      <Route path={ENDPOINT_LOGIN} element={<SignIn />} />
      <Route path={ENDPOINT_CATEGORY_PRODUCTS} element={<CategoryProducts />} />
      <Route path={ENDPOINT_PRODUCTS_SEARCH} element={<SearchResultsPage />} />
      <Route path='/pay' element={<Payment/>} />
      </Route>
      <Route path={ENDPOINT_PRODUCT_DETAILS} element={<SingleProductPage />} />

      <Route element={<AuthenticatedRoute/>}>
      <Route path={ENDPOINT_TRACK_ORDER} element={<TrackOrder />} />
      <Route path={ENDPOINT_CHECKOUT} element={<CheckoutPage/>} />
      <Route path={ENDPOINT_ACCOUNT} element={<AccountPage />} />

      </Route>

      <Route path={ENDPOINT_CONTACT} element={<ContactPage />} />
      <Route path={ENDPOINT_PRODUCTS} element={<ShopPage />} />
      <Route path={ENDPOINT_TERMS} element={<TermsAndConditionsPage/>} /> 
      <Route path={ENDPOINT_PRIVACY_POLICY} element={<PrivacyPolicy/>} />
      <Route path={ENDPOINT_CART} element={<CartPage />} />
      <Route path='*' element={<NotFound/>} />
    </Routes>
<ToastContainer />

    </>
  );
}

export default App;
