import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { logOut, selectCurrentToken, selectUser } from '../../features/auth/authSlice'
import { getTotals } from '../../features/cart/cartSlice'
import { ENDPOINT_ACCOUNT, ENDPOINT_CART, ENDPOINT_CONTACT, ENDPOINT_HOMEPAGE, ENDPOINT_LOGIN, ENDPOINT_PRODUCTS, ENDPOINT_PRODUCTS_SEARCH } from '../../routes'
import SignIn from '../auth/SignIn'
import Signup from '../auth/Signup'

function Header() {

    const { pathname } = useLocation()
    const { cartTotalQuantity } = useSelector((state) => state.cart)
    const [searchName, setSearchName ] = useState('')
    const token = useSelector(selectCurrentToken)
    const username = useSelector(selectUser)
    const [activeSearh, setActiveSearch] = useState(false)
    const [open, setOpen] = useState(false)
    const [reg, setReg] = useState(false)


    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseReg = () => {
        // console.log('Close called')
        setReg(false)
    }


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch(logOut())
        navigate(ENDPOINT_HOMEPAGE)
    }

    const cart = useSelector((state) => state.cart)


    const handleSearchSubmit = (e) => {
        e.preventDefault()
        navigate(`${ENDPOINT_PRODUCTS_SEARCH}?q=${searchName}`);
    }
    // console.log('token in state', token)
    // console.log('user in state', username)
    const [showNav, setShowNav] = useState(false)

    useEffect(() => {

        dispatch(getTotals())
    }, [cart, dispatch])

  return (
    <div className={`bg-[#FF6600] w-full fixed z-50  px-2 py-3 flex flex-col ${showNav? 'flex-col-reverse':''}`}>
        {open && 
        <SignIn open={open} toPath={pathname} handleClose={handleClose}/>
        }
        {reg &&
        <Signup open={reg} handleCloseSignup={handleCloseReg}/>
}
        <nav className={`${showNav? '':'hidden md:flex '}  container flex-wrap justify-between items-center mx-auto text-white font-satoshi`}>
            <div className="flex flex-wrap">
                <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                +1 (540) 892-4794
                </p>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                    hello@enthusiastic-art.com
                </p>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                    California 
                </p>

            </div>
            <div className="flex flex-wrap">
            <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                    Support
                </p>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                {token?
                <>
                <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                  Welcome, {username}
                </p>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                <div className="flex flex-row gap-2 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>

                <Link to={ENDPOINT_ACCOUNT}>
                <p
                 className="font-satoshi text-white text-[16px] cursor-pointer ">
                    Account 
                </p>
                </Link>
                </div>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                <p
                onClick={handleLogout}
                 className="font-satoshi text-white text-[16px] cursor-pointer ">
                    Logout 
                </p>
                </>
                :
                <>
                <p 
                onClick={() =>setReg(true)}
                className="font-satoshi text-white text-[16px] cursor-pointer ">
                    Register
                </p>
                <div className="border-r border-1 ml-2 mr-2 border-white"></div>
                <div onClick={()=> setOpen(true)}>
                <p className="font-satoshi text-white text-[16px] cursor-pointer ">
                    Login 
                </p>
                </div>
                </>
                }
            </div>


            </nav>
        <nav className='container flex flex-wrap gap-2 md:gap-10 justify-between items-center mx-auto text-white font-satoshi'>
            <Link to={ENDPOINT_HOMEPAGE}>
            <p className="font-satoshi text-white text-[40px] font-bold">
                    Enthusiastic. 
                </p>
            </Link>
            <div className="flex md:hidden flex-row gap-4">
            <Link to={ENDPOINT_CART}> 
                <p className="font-satoshi text-white relative  text-[18px] cursor-pointer font-medium">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 font-semibold">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
 
                <p className="font-satoshi text-[#FF6600]  text-[11px] bg-white px-2 py-1 rounded-full cursor-pointer font-semibold mt-2 absolute left-4 -top-4">
                {cartTotalQuantity}
 
                </p>
                </p>
                </Link>

            <div
            onClick={()=> setShowNav(!showNav)}
             className="cursor-pointer block md:hidden">
                {showNav?
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              
                :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
                }

            </div>
            </div>
            <div className={` w-auto grow ${showNav? 'block':'hidden md:block'}`}>

            <ul className='flex flex-1 text-white tems-start m:ditems-center flex-col gap-2 p-0 md:p-4 mt-4 md:flex-row'>
            <Link to={ENDPOINT_HOMEPAGE}>
                <li>
                <p className="font-satoshi text-white  text-[18px] cursor-pointer font-medium mt-2">
                    Home 
                </p>
                </li>
                </Link>
                <li className='grow w-full md:w-[70%]'>
                    <div className="relative w-full">

                    <form onSubmit={handleSearchSubmit}  className='w-full'>
                        <input
                        value={searchName}
                        onChange={(e) => {setSearchName(e.target.value)}}
                         type="text" className='relative w-full px-4 py-2 bg-white rounded-sm text-black font-satoshi outline-none' />
                        <svg 
                        onClick={handleSearchSubmit}
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`absolute w-6 h-6 top-2 right-2 cursor-pointer fonnt-semibold  ${activeSearh? 'text-[#FF6600]': 'text-black'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>

                    </form>
                    </div>
                </li>
                <Link to={ENDPOINT_PRODUCTS}>

                <li>
                <p className="font-satoshi text-white  text-[18px] cursor-pointer font-medium mt-2">
                    Shop 
                </p>
                </li>
                </Link>
                <Link to={ENDPOINT_CONTACT}>

                <li>
                <p className="font-satoshi text-white  text-[18px] cursor-pointer font-medium mt-2">
                    Contact us 
                </p>
                </li>
                </Link>
                <Link to={ENDPOINT_CART}> 
                <li className=''>
                <p className="font-satoshi text-white relative  text-[18px] cursor-pointer font-medium mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-7 h-7 font-semibold">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
 
                <p className="font-satoshi text-[#FF6600]  text-[11px] bg-white px-2 py-1 rounded-full cursor-pointer font-semibold mt-2 absolute left-4 -top-4">
                {cartTotalQuantity}
 
                </p>
                </p>
                </li>
                </Link>

                
            </ul>
            </div>

        </nav>

    </div>
  )
}

export default Header