import React from 'react'
import { useState } from 'react'
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'
import axios from 'axios'
import { toast } from 'react-toastify'

function ContactPage() {
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [subject,setSubject] = useState('')
    const [name,setName] = useState('')
    const [message,setMessage] = useState('')
    const [loading, setLoading] = useState(false)
  const baseUrl = 'https://staging--api-enthusiastic.up.railway.app/api/v1/send-contact/'
  
  const handleSendMessage = async () => {
    setLoading(true)
    if(phone !== ""){
        setLoading(false)
        // toast.error('')
        return
    }
    const payload = {
      email,
      message,
      subject,
      name
    }
    try {
      const response = await axios.post(`${baseUrl}`, payload);
      toast.success('Your message was successfully submitted. Our customer care representative will get back to you.')
      setLoading(false)
      setMessage("")
      setSubject("")
      setName("")
      setEmail("")
    } catch (error) {
      console.log(error);
      toast.error('An error occured. Please try again.')
      setLoading(false)

    }
  }

  return (
    <div className='flex flex-col w-full h-full gap-4'>
                        <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
        <div className="flex flex-col md:flex-row gap-10 w-full h-full container mx-auto justify-center items-center min-h-[60vh] px-2">
            <div className="w-full md:w-[60%]">
                <form action="" className='w-full h-full font-satoshi text-[16px] flex flex-col gap-2'>
                    <div className="w-full flex flex-col gap-1">
                        <label htmlFor="email">Email</label>
                        <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                         type="text" name="email" className={`font-satoshi outline-none  px-4 py-2 rounded-md ${email.length > 0 ? 'border-2 border-[#FF6600]':'border'}`} id="" />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                        <label htmlFor="name">Name</label>
                        <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                         type="text" name="name" className={`font-satoshi  outline-none  px-4 py-2 rounded-md ${name.length > 0 ? 'border-2 border-[#FF6600]':'border'}`} id="" />
                    </div>
                    {/* <div className="w-full flex flex-col gap-1"> */}
                        {/* <label htmlFor="name">phone</label> */}
                        <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                         type="hidden" name="phone" className={`font-satoshi  outline-none  px-4 py-2 rounded-md ${name.length > 0 ? 'border-2 border-[#FF6600]':'border'}`} id="" />
                    {/* </div> */}
                    <div className="w-full flex flex-col gap-1">
                        <label htmlFor="subject">Subject</label>
                        <input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                         type="text" name="subject" className={`font-satoshi  outline-none  px-4 py-2 rounded-md ${subject.length > 0 ? 'border-2 border-[#FF6600]':'border'}`} id="" />
                    </div>
                    <div className="w-full flex flex-col">
                        <label htmlFor="message">Message</label>
                        <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                         rows={5} type="text" name="message" className={`font-satoshi  outline-none  px-4 py-2 rounded-md ${message.length > 0 ? 'border-2 border-[#FF6600]':'border'}`} id="" />
                    </div>
                    <div className="flex flex-row w-[20%]">
                        {email.length > 0 && subject.length > 0 && message.length > 0 &&
                        <button onClick={handleSendMessage} disabled={loading} className='font-satoshi w-full px-2 py-2 text-white bg-[#FF6600] rounded-md'>{loading?"Sending...":"Send"}</button>
                        }
                    </div>
                </form>
            </div>
            <div className="w-full md:w-[40%] flex flex-col gap-5 h-full justify-center items-center">
                <div className="w-full flex flex-col gap-2 ">
                    <p className='font-satoshi text-[18px] md:text-[24px] underline underline-offset-4 decoration-2 font-semibold'>Our shop</p>
                    {/* <p className='font-satoshi text-[14px] md:text-[18px] '>Bazaar Plaza, Mezzanine 1,
                    </p> */}
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    4510 Cordell Dr. Roanoke. VA. 24018</p>

                </div>
                <div className="w-full flex flex-col gap-2">
                    <p className='font-satoshi text-[18px] md:text-[24px] underline underline-offset-4 decoration-2 font-semibold'>Call us </p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>+1 (434) 922-0285
                    </p>
                    {/* <p className='font-satoshi text-[14px] md:text-[18px] '>
                    +1 (540) 892-4794</p> */}

                </div>
                <div className="w-full flex flex-col gap-2">
                    <p className='font-satoshi text-[18px] md:text-[24px] underline underline-offset-4 decoration-2 font-semibold'>Operation hours </p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>Monday: 7 AM – 10 PM
                    </p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Tuesday: 7 AM – 10 PM</p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Wednesday: 7 AM – 10 PM</p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Thursday: 7 AM – 10 PM</p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Friday: 7 AM – 10 PM</p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Saturday: 7 AM – 10 PM</p>
                    <p className='font-satoshi text-[14px] md:text-[18px] '>
                    Sunday Closed</p>

                </div>
            </div>
        </div>
        <RevFooter/>

    </div>
  )
}


export default ContactPage