import React from 'react'
import ProductCard from './ProductCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const LandingGrid = ({products, loading}) => {
  return (
    <>
     {loading && 
    <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
    <Skeleton height={300}/>
    <Skeleton height={300}/>
    <Skeleton height={300}/>
    <Skeleton height={300}/>
    <Skeleton height={300}/>
    <Skeleton height={300}/>
</div>
     }
{products?.length > 0 &&
    <div className="grid grid-cols-1 px-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 w-full gap-4 py-8 ">
        
            {products?.map((product) => (

        <>
        <ProductCard product={product} key={product?.id}/>
        </>
        ))
        }      
  </div>}
    </>
  )
}

export default LandingGrid