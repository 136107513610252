import {IoCloseSharp, IoMailUnreadOutline } from "react-icons/io5"
import { HiMiniPhone } from "react-icons/hi2"
import {MdLocationOn} from "react-icons/md"
import {BsPerson} from "react-icons/bs"
import { BiSearch, BiShoppingBag } from "react-icons/bi"
import { AiOutlineHeart, AiOutlineLogout } from "react-icons/ai"
import { HiOutlineMenuAlt3 } from "react-icons/hi"
import { TbMinusVertical } from "react-icons/tb"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { ENDPOINT_ACCOUNT, ENDPOINT_CART, ENDPOINT_CONTACT, ENDPOINT_HOMEPAGE, ENDPOINT_PRODUCTS, ENDPOINT_PRODUCTS_SEARCH } from "../../routes"
import { useEffect, useState } from "react"
import { getTotals } from "../../features/cart/cartSlice"
import { useDispatch, useSelector } from "react-redux"
import { logOut, selectCurrentToken, selectUser } from "../../features/auth/authSlice"
import Signup from "../auth/Signup"
import SignIn from "../auth/SignIn"
import OnBoarding from "../auth/OnBoarding"
const TopBar = () => {
    let currentUrlParams = new URLSearchParams(window.location.search)
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const[showNav, setShowNav] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const { pathname } = useLocation()
    const { cartTotalQuantity } = useSelector((state) => state.cart)
    const [searchName, setSearchName ] = useState('')
    const token = useSelector(selectCurrentToken)
    const username = useSelector(selectUser)
    const [activeSearh, setActiveSearch] = useState(false)
    const [open, setOpen] = useState(false)
    const [reg, setReg] = useState(false)
    const [login, setLogin] = useState(false)
    const [show, setShow] = useState(false)


    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseReg = () => {
        // console.log('Close called')
        setReg(false)
    }


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogout = () => {
        dispatch(logOut())
        navigate(ENDPOINT_HOMEPAGE)
    }

    const cart = useSelector((state) => state.cart)


    const handleSearchSubmit = (e) => {
        e.preventDefault()
        navigate(`${ENDPOINT_PRODUCTS}?q=${searchName}`);
    }


    useEffect(() => {

        dispatch(getTotals())
    }, [cart, dispatch])

    useEffect(() => {
      window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      }, false);
    }, [isMobile]);
  return (
    <>

    <nav className={`font-satoshi z-50 sticky w-full flex flex-col gap-1 ${isMobile ? 'px-1':'py-2'}`}>
        {!isMobile &&
        <div className="flex w-full flex-row-reverse items-center">
        <div className="flex flex-row-reverse items-center gap-4">
            <div className="flex flex-row gap-1 items-center">
                <IoMailUnreadOutline size={20} className=""/>
                <p className="text-gray-600 text-[18px]">uzuricraft@gmail.com</p>
            </div>
            <TbMinusVertical size={25} className="text-gray-500"/>
            <div className="flex flex-row gap-1 items-center">
                <HiMiniPhone size={20} className=""/> 
                <p className="text-gray-600 text-[16px]">+1 (434) 922-0285</p>
            </div>
            <TbMinusVertical size={25} className="text-gray-500"/>

            <div className="flex flex-row gap-1 items-center">
                <MdLocationOn size={20} className=""/>
                <p className="text-gray-600 text-[16px]">4510 Cordell Dr. Roanoke. VA. 24018</p>
            </div>
        </div>
        </div>
        }
        <div className={`flex ${isMobile? 'flex-col':'flex-row items-center'}  gap-2  bg-white shadow-md rounded-sm p-4 justify-between`}>
        <div className="flex flex-row gap-2 items-center justify-between">
            <Link to={ENDPOINT_HOMEPAGE}>
            <img src="/cdn/images/sulogo.png" className="w-[9rem]" alt="" />
            </Link>
            {/* <p className="font-bold text-gray-800 text-2xl">Uzuricraft.</p> */}
            {isMobile && 
            <div className="flex flex-row items-center gap-6">
            <Link to={ENDPOINT_CART} className="relative">
                <div className="relative">

            <BiShoppingBag size={30} className="text-gray-600"/>
                </div>
                <p className="font-satoshi text-white  text-[12px] bg-indigo-600 px-2 py-1 rounded-full cursor-pointer mt-2 absolute left-4 -top-4">
                {cartTotalQuantity}
 
                </p>
            </Link>
            <div onClick={() => (setShowNav(!showNav))} className=" flex flex-row items-center cursor-pointer">
                {showNav ?
                <IoCloseSharp size={30} className="text-gray-600"/>
                :
                <HiOutlineMenuAlt3 size={30} className="text-gray-600"/>
                }
            </div>
            </div>
            }
        </div>
        <div className={`flex ${isMobile? 'flex-col':'flex-row items-center gap-6'} ${isMobile && showNav && 'flex duration-500 transition'} ${isMobile && !showNav && 'hidden'}  flex-1 justify-end`}>
            <form onSubmit={handleSearchSubmit} className={`flex flex-row items-center border border-gray-300 rounded-md px-3 py-1 overflow-hidden ${isMobile ? 'w-full':'w-[30%]' }`}>
                <input type="text" value={searchName} onChange={(e) => setSearchName(e.target.value)} className="outline-none flex-1 text-gray-600 text-[18px]" placeholder="Search products" />
                <div className="flex flex-col items-end">
                    <BiSearch size={20} className="text-gray-600"/>
                </div>
            </form>
            <Link to={ENDPOINT_HOMEPAGE} className="text-gray-600 text-[18px]">Home</Link>
            <Link to={ENDPOINT_PRODUCTS} className="text-gray-600 text-[18px]">Shop</Link>
            <Link to={ENDPOINT_PRODUCTS} className="text-gray-600 text-[18px]">Best selling</Link>
            <Link to={ENDPOINT_CONTACT} className="text-gray-600 text-[18px]">Help</Link>
            {!isMobile &&
            <Link to={ENDPOINT_CART} className="relative">
                <div className="relative">

            <BiShoppingBag size={25} className="text-gray-600"/>
                </div>
                <p className="font-satoshi text-white  text-[12px] bg-indigo-600 px-2 py-1 rounded-full cursor-pointer mt-2 absolute left-4 -top-4">
                {cartTotalQuantity}
 
                </p>
            </Link>}

            <Link>
            <AiOutlineHeart size={25} className="text-gray-600"/>
            </Link>
            {token &&
            <Link to={ENDPOINT_ACCOUNT} className="flex flex-row gap-1 items-center">

            <BsPerson size={25} className="text-gray-600"/>
            <div className="flex flex-col justify-center">
                <p className="text-[16px] text-gray-600">Account</p>
            </div>
            </Link>
            }
            {token &&
            <div onClick={handleLogout} className="flex flex-row gap-1 items-center hover:text-indigo-600 cursor-pointer">

            <AiOutlineLogout size={25} className="text-gray-600"/>
            <div className="flex flex-col justify-center">
            <p  className="text-gray-600 text-[16px]">Logout</p>
            </div>
            </div>
            }
            {!token && <div className="flex flex-row gap-2">

            <p onClick={() => {
                setLogin(true)
                setOpen(true)
                setReg(false)
            }} className="text-gray-600 text-[16px] cursor-pointer hover:text-indigo-600">Sign In</p>
            <p onClick={() => {
                setLogin(true)
                setOpen(true)
                setReg(true)
            }} className="text-gray-600 text-[16px] cursor-pointer hover:text-indigo-600">Sign Up</p>

            </div>}

        </div>
        </div>

    </nav>
    {login &&
    <OnBoarding open={open} reg={reg} setOnBoarding={setLogin}/>
    }
    </>

  )
}

export default TopBar