import { configureStore, createReducer } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import { authApiSlice } from "./api/authApiSlice";
import authReducer from '../features/auth/authSlice'
import cartReducer from '../features/cart/cartSlice'

export const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]: apiSlice.reducer,
        [authApiSlice.reducerPath]:authApiSlice.reducer,
        auth:authReducer,
        cart:cartReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
        apiSlice.middleware,
        authApiSlice.middleware
    ]),
})