import React from 'react'
import Cart from '../components/Cart'
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'

function CartPage() {
  return (
    <div className='bg-white flex flex-col w-full'>
                <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
    <div className="min-h-[40ch] md:min-h-[65vh] flex flex-col">

        <Cart/>
    </div>
    <RevFooter/>

    </div>
  )
}

export default CartPage