import React from 'react'
import LanscapeProduct from './LanscapeProduct'

const CrazyOffers = ({title, products}) => {
  return (
    <div className="flex flex-col w-full h-full gap-2 relative">
    <div className="flex flex-row w-full justify-between bg-[#FFF2E9] rounded-sm p-2">
<div className="cursor-pointer">
        <p className={`font-satoshi font-semibold text-[20px] underline underline-offset-[14px]  decoration-[6px] text-indigo-600`}>{title}</p>
    </div>

</div>
<div className=" flex flex-col  w-full gap-2">

<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 gap-4 px-2 py-3">
    {products?.map((product) => (
      <LanscapeProduct product={product}/>
    ))}
</div>

</div>
</div>
  )
}

export default CrazyOffers