import React, { useEffect, useState } from 'react'
import AccountDetails from '../components/AccountDetails'
import Orders from '../components/Orders'
import { useGetCurrentUserQuery } from '../features/auth/authenticationSlice'
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'



const ORDER_HISTORY_TAB = 'Orders'
const PROFILE_TAB = 'Profile'
const WISH_LIST_TAB = 'Wish list'



function AccountPage() {
    const { data: currentUser } = useGetCurrentUserQuery()
    // console.log('currentUser',currentUser)
    const [currentTab, setCurrentTab] = useState(ORDER_HISTORY_TAB)

    useEffect(() => {

    }, [currentUser])
  
  return (
    <div className="">

<div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
    <div className='flex flex-col md:flex-row gap-6 w-full h-full py-10 container mx-auto min-h-[65vh] px-2'>
        {/* {currentUser?.id} - {currentUser?.email} - {currentUser?.username} */}
        <div className="flex flex-col gap-2 w-full md:w-[20%]">
          <div
          onClick={() => setCurrentTab(ORDER_HISTORY_TAB)}
           className={` ${currentTab === ORDER_HISTORY_TAB ? 'bg-[#FFEFE4]  text-gray-600':'bg-gray-200'} font-satoshi text-[16px] px-4 py-2 rounded-sm cursor-pointer outline-none`}>Order History
           </div>
           <div
          onClick={() => setCurrentTab(PROFILE_TAB)}
           className={` ${currentTab === PROFILE_TAB ? 'bg-[#FFEFE4]  text-gray-600':'bg-gray-200'} font-satoshi text-[16px] px-4 py-2 rounded-sm cursor-pointer outline-none`}>Profile
           </div>
           <div
          onClick={() => setCurrentTab(WISH_LIST_TAB)}
           className={` ${currentTab === WISH_LIST_TAB ? 'bg-[#FFEFE4]  text-gray-600':'bg-gray-200'} font-satoshi text-[16px] px-4 py-2 rounded-sm cursor-pointer outline-none`}>Wish List
           </div>
        </div>
        <div className="flex flex-col w-full md:w-[80%]">
        {currentTab === ORDER_HISTORY_TAB && (
          <Orders/>
        )}
          {currentTab === PROFILE_TAB && (
          <AccountDetails currentUser={currentUser}/>
        )}


        </div>
        
    </div>
    <RevFooter/>
    </div>
  )
}

export default AccountPage