import React, { useState, useEffect } from 'react'
import HomeSidebar from '../components/HomeSidebar'
import Pagination from '../components/Pagination'
import Products from '../components/Products'
import { useGetProductsSearchResultQuery } from '../features/products/productsApiSlice'

function SearchResultsPage() {
    const currentUrlParams = new URLSearchParams(window.location.search)
    const searchTerm = currentUrlParams.get('q')
    const [page_size, setPage_size ] = useState(5)
    const [page, setPage ] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [nextPage, setNextPage] = useState(2)
    const [prevPage, setPrevPage] = useState(1)
    const [sortBy, setSortBy] = useState('')
    const [sortByPrice, setSortByPrice] = useState('')
  
  
    const handlesortBy = (data) => {
      setSortBy(data);
    };
  
    const handlesortPrice = (dprice) => {
      setSortByPrice(dprice)
  
    }

    const { data:products, isLoading } = useGetProductsSearchResultQuery({page:page, searchTerm:searchTerm, date:sortBy, price:sortByPrice})
    // console.log('search',searchTerm)

    useEffect(() => {

        setNextPage(products?.next_page)
        setPrevPage(products?.previous_page)
        setTotalPages(products?.total_pages)
        setCurrentPage(products?.current_page_number)
      },[products])
    
    
    
    
      const fetchNextPage = () => {
      setPage(products?.next_page)
      }
    
      const fetchPreviousPage = () => {
      setPage(products?.previous_page)
      }
    
      const fetchFirstPage = () => {
        setPage(1)    
        }
    
      const fetchLastPage = () => {
        setPage(products?.total_pages)
    
      }  


    
      
      // console.log('products',products)

  return (
    <>
    
    <div className="w-full h-full  flex flex-col md:flex-row gap-4">
      <div className="w-full md:w-[20%]">
        <HomeSidebar showCategories={true} handlesortBy={handlesortBy} handlesortPrice={handlesortPrice}/>
      </div>
        <div className="w-full md:w-[80%] flex flex-col gap-4">
        <div className="flex justify-end">

          <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>

          </div>

      <Products products={products} isLoading={isLoading}/>
      <div className="flex justify-center">

        <Pagination 
          currentPage={currentPage}
          totalPages={totalPages}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          fetchFirstPage={fetchFirstPage}
          fetchLastPage={fetchLastPage}/> 

      </div>
        </div>
    
     
    </div>
    
    </>
  )
}

export default SearchResultsPage