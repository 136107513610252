import { LS_ACCESS_TOKEN } from "./constants"

export const isAuthenticated = () => {
    const token = sessionStorage.getItem(LS_ACCESS_TOKEN)
    if (token) {
        // check if token is valid from backend
        return true
    }
    return false
}

export const makeid = () => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    result += "-"
    for (var i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return `ENTHU-${result}`;
  }
  