// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0JU-6egvYo4SsN7YAHHJdbATeE2Y5Hfc",
  authDomain: "entusiastic-79d4a.firebaseapp.com",
  projectId: "entusiastic-79d4a",
  storageBucket: "entusiastic-79d4a.appspot.com",
  messagingSenderId: "454190450913",
  appId: "1:454190450913:web:5055cfc9307fd469c5cfa0",
  measurementId: "G-TGTYHXTQ35"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);


// export const uploadFileToFirebase = (file) => {
//   const headers = {
//     Authorization: `Bearer ${firebaseToken}`,
//     'Content-Type': 'application/octet-stream',
//   };

//   const metadata = {
//     contentType: file.type,
//   };

//   return storage.ref().child(file.name).put(file, { headers, metadata });
// };
// const metadata = {
//   contentType: "image/jpeg", 
// };
