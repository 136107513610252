import { authApiSlice } from "../../app/api/authApiSlice";

export const authenticationSlice = authApiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/auth/token/',
                method: 'POST',
                body : credentials


            })
        }),

        register: builder.mutation({
            query: credentials => ({
                url: '/auth/user-signup/',
                method: 'POST',
                body : credentials


            })
        }),
        getCurrentUser: builder.query({
            query: () => `/auth/profile/`
            
        }),
        currentUserUpdate: builder.mutation({
            query: credentials => ({
                url: '/auth/profile/update/',
                method: 'PUT',
                body : credentials


            })
        }),
        currentUserProfileUpdate: builder.mutation({
            query: credentials => ({
                url: '/auth/user/profile/update/',
                method: 'PUT',
                body : credentials


            })
        }),

        createBasket: builder.mutation({
            query: cartData => ({
                url: '/basket/create/',
                method: 'POST',
                body : cartData


            })
        }),
        transferCartFromLocalstorageToApi: builder.mutation({
            query: cartItems => ({
                url: '/basket-items/create/',
                method: 'POST',
                body : cartItems


            })
        }),
        getCurrentUserBasket: builder.query({
            query: () => `/current-user/basket/`
            
        }),
        getCurrentUserBaskets: builder.query({
            query: ({page}) => `/current-user/baskets/?sort=date&page=${page}`
            
        }),
        getBasket: builder.query({
            query: (id) => `/baskets/${id}`,

        }),
    })
})


export const { 
    useLoginMutation, 
    useGetCurrentUserQuery, 
    useCurrentUserUpdateMutation, 
    useCurrentUserProfileUpdateMutation,
    useTransferCartFromLocalstorageToApiMutation,
    useGetCurrentUserBasketQuery,
    useCreateBasketMutation,
    useGetCurrentUserBasketsQuery,
    useRegisterMutation,
    useGetBasketQuery,
 } = authenticationSlice