import { Link } from "react-router-dom"
import {MdArrowRightAlt} from "react-icons/md"
import { ENDPOINT_PRODUCTS } from "../../routes"

const LanscapeProduct = ({product}) => {
  return (
    <Link  to={`${ENDPOINT_PRODUCTS}/${product?.id}`} className="flex flex-row gap-2 font-satoshi p-1 bg-slate-100 rounded-md shadow-md">
        <img src={product?.thumbnail_url}
        className="w-[5rem] h-[6rem]"
        alt={product?.name} />
        <div className="flex flex-col gap-1">
            <p className="text-gray-700 uppercase text-[10px] font-semibold">{product?.category?.name}</p>
            <p className="text-[#FF780C] text-[16px] font-semibold">{product?.name}</p>
            <div className="flex flex-row gap-1 items-center">

            <p className="text-red-600 text-[14px] font-semibold">${product?.new_price}</p>
            <p className="text-gray-800 text-[14px] line-through">${product?.old_price}</p>
            </div>
            <Link className='rounded-md py-1.5 flex flex-row items-center gap-2'>
                <p className='text-gray-800 text-[14px]'>Shop now</p>
                <MdArrowRightAlt className='text-gray-800 ' size={20}/>
            </Link>

        </div>
    </Link>
  )
}

export default LanscapeProduct