import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../common/Footer'
import Header from '../common/Header'

function Layout() {
  return (
    <>
    <Header/>
    <div className="w-full container h-full min-h-[80vh] mx-auto pt-24 md:pt-40 pb-10 px-2 ms:px-10 ">
        <Outlet />
    </div>
    <Footer />
    </>
  )
}

export default Layout