import React, { useEffect, useState } from 'react'
import { useCurrentUserProfileUpdateMutation, useCurrentUserUpdateMutation, useGetCurrentUserBasketQuery } from '../features/auth/authenticationSlice'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { makeid } from '../utils';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { storage } from "../firebaseUtils";
import Avatar from 'react-avatar';
import { setCurrentCart } from '../features/auth/authSlice';

// export const compresedImage = (file) =>
//    new Promise((resolve) => {
//       new Compressor(file, {
//          minWidth: 640,
//          maxHeight: 640,
//          mimeType: 'image/webp',
//          success(result) {
//             resolve(result)
//          },
//          error(err) {
//             console.log(err.message);
//          },
//       });
//    });

function AccountDetails({currentUser}) {

    const { firebaseToken } = useSelector((state) => state.auth)

    const uploadFile = async (file) => {
      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file, {
        customMetadata: {
          accessToken: firebaseToken,
        },
      });
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    }
   
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')

    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [avatar, setAvatar] = useState('')

    const [home_address, setHomeAddress] = useState('')
    const [work_address, setWorkAddress] = useState('')
    const [default_address, setDefaultAddress] = useState('')
    const [phone_number, setPhoneNumber] = useState('')


    const dispatch = useDispatch()


    const [edit, setEdit] = useState(true)
    const [editprofile, setEditProfile] = useState(true)





  //   const handleFileUploadOnFirebaseStorage = async (uploadedImage) => {
  //     console.log(uploadedImage)
  //     const bucketName = `/inventory`
  //     if (uploadedImage === "" || uploadedImage === null) return "";
  //     const fileID = makeid();
  //     const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);
  //     await uploadBytesResumable(storageRef, uploadedImage);
  //     let downloadURL = null;
  //     await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
  //     return downloadURL
  //  };

    const handleEdit = () => {
        setEdit(false)
    }

    const handleEditProfile = () => {
        setEditProfile(false)
    }

    useEffect(() => {
        setUsername(currentUser?.user.username)
        setEmail(currentUser?.user.email)
        setAvatar(currentUser?.avatar)
        setHomeAddress(currentUser?.home_address)
        setWorkAddress(currentUser?.work_address)
        setDefaultAddress(currentUser?.default_address)
        setPhoneNumber(currentUser?.phone_number)
        setFirstName(currentUser?.user.first_name)
        setLastName(currentUser?.user.last_name)

    },[currentUser])

    const [ currentUserUpdate ] = useCurrentUserUpdateMutation()

    // const { data:currentBasket } = useGetCurrentUserBasketQuery()
    // dispatch(setCurrentCart(currentBasket))

    const [ currentUserProfileUpdate ] = useCurrentUserProfileUpdateMutation()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{

            const userData = await currentUserUpdate({ username, email, first_name, last_name }).unwrap()
            // console.log('userData', userData)
            // console.log('username to state', username)

            // dispatch(setCredentials({ ...userData, username}))
            setEmail('')
            setUsername('')
            setEdit(true)
            toast.info(`User updated successfully`,
            {
              position: "bottom-center",
            })
            // console.log('welcome redirect')
            setTimeout(() => {
                window.location.reload();
              }, 2000);
        }catch (err) {
            if(!err?.originalStatus){

                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('NO server response',err)
            } else if (err?.originalStatus.status === 400){
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Missing username or password')
            } else if (err?.originalStatus.status === 401){
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Unauthorized')
            } else {
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Something went wrong', err)
            }

        }
    }


    const handleSubmitProfile = async (e) => {
        e.preventDefault();
        try{

            const userData = await currentUserProfileUpdate({user:currentUser?.user, avatar, home_address, work_address, default_address, phone_number }).unwrap()
            // console.log('userData', userData)
            // console.log('username to state', username)

            // dispatch(setCredentials({ ...userData, username}))
            setEmail('')
            setUsername('')
            setEditProfile(true)
            toast.info(`Profile updated successfully`,
            {
              position: "bottom-center",
            })
            console.log('welcome redirect')
            setTimeout(() => {
                window.location.reload();
              }, 2000);
        }catch (err) {
            if(!err?.originalStatus){

                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('NO server response',err)
            } else if (err?.originalStatus.status === 400){
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Missing username or password')
            } else if (err?.originalStatus.status === 401){
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Unauthorized')
            } else {
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Something went wrong', err)
            }

        }
    }


    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    const [image, setImage] = useState(null);
    const [url, setUrl] = useState("");
  
    const handleChange = (e) => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
      }
    };
  
    const handleUpload = async () => {
      if (!image) return;
  
      const downloadUrl = await uploadFile(image);
      setUrl(downloadUrl);
    };
  

    // console.log('url',url)
    // console.log('image',image)



  return (
    <>
    <div className="flex flex-col w-full gap-4 ">

    <div className='flex flex-col md:flex-row w-full gap-4'>
        
        <div className="flex flex-col w-full md:w-6/12 gap-2 border px-4 py-2 rounded-md shadow-sm">
        <div className="flex flex-row-reverse gap-2">
    {!edit &&
     <button
     onClick={() => setEdit(true)}
      className='bg-gray-200 px-4 py-2 font-satoshi outline-none text-[16px] rounded-md'>Cancel</button>
    }
    {edit &&
            <button
            onClick={handleEdit}
             className='bg-[#FF6600] px-4 py-2 font-satoshi outline-none text-[16px] text-white rounded-md'>Edit</button>
    }
             {!edit &&
             <button
            onClick={handleSubmit}
             className='bg-[#FF6600] px-4 py-2 font-satoshi outline-none text-[16px] text-white rounded-md'>Save</button>
        }
        </div>
            <form onSubmit={handleSubmit} className='w-full flex flex-col gap-2'>
                <label className='font-satoshi text-[14px]'>Username</label>
                <input readOnly={edit}
                 placeholder='Username'

                onChange={(e) => setUsername(e.target.value)}
                 type="text" name="" id="" value={username} className={`${edit? 'border-gray-200':'border-[#FF6600]'} font-satoshi w-full px-4 py-2 outline-none rounded-md border`} />
                
                <label className='font-satoshi text-[14px]'>Email</label>
                <input readOnly={edit}
                 placeholder='Email'

                onChange={(e) => setEmail(e.target.value)}
                 type="text" name="" id="" value={email} className={`${edit? 'border-gray-200':'border-[#FF6600]'} font-satoshi w-full px-4 py-2 outline-none rounded-md border`} />

                {/* {!edit && */}
                <>
                <label className='font-satoshi text-[14px]'>First name</label>
                <input readOnly={edit}
                 placeholder='First name'

                onChange={(e) => setFirstName(e.target.value)}
                 type="text" name="" id="" value={first_name} className={`${edit? 'border-gray-200':'border-[#FF6600]'} font-satoshi w-full px-4 py-2 outline-none rounded-md border`} />
                 
                <label className='font-satoshi text-[14px]'>Last name</label>
                 <input readOnly={edit}
                 onChange={(e) => setLastName(e.target.value)}
                 placeholder='Last name'
                  type="text" name="" id="" value={last_name} className={`${edit? 'border-gray-200':'border-[#FF6600]'} font-satoshi w-full px-4 py-2 outline-none rounded-md border`} />
                </>
                {/* } */}
            </form>

        </div>
        <div className="flex flex-col w-full md:w-6/12 gap-4 border px-4 py-2 rounded-md shadow-sm">
        <div className="flex flex-row-reverse gap-2">
    {!editprofile &&
     <button
     onClick={() => setEditProfile(true)}
      className='bg-gray-200 px-4 py-2 font-satoshi outline-none text-[16px] rounded-md'>Cancel</button>
    }
    {editprofile &&
            <button
            onClick={handleEditProfile}
             className='bg-[#FF6600] px-4 py-2 font-satoshi outline-none text-[16px] text-white rounded-md'>Edit profile</button>
    }
             {!editprofile &&
             <button
            onClick={handleSubmitProfile}
             className='bg-[#FF6600] px-4 py-2 font-satoshi outline-none text-[16px] text-white rounded-md'>Save</button>
        }
        </div>
            {/* <img src={avatar} className={`${editprofile? 'border-gray-200':'border-[#FF6600]'} rounded-full border h-24 w-24 p-2`} alt="" /> */}
            <Avatar name={`${username}`} round={true} />
            <div className="flex flex-col gap-2 w-full">
            <div className="flex md:grid flex-col md:grid-cols-2 gap-2 w-full">
                <div className="flex flex-col w-full px-4 py-2 gap-2 rounded-md shadow-md border">
                    <p className='font-satoshi text-[16px] font-semibold'>Home</p>
                    <input readOnly={editprofile}
                 onChange={(e) => setHomeAddress(e.target.value)}
                 placeholder='Home address'
                  type="text" name="" id="" value={home_address} className={`${editprofile? 'border-white':'border-[#FF6600]'} font-satoshi w-full px-2 py-2 outline-none rounded-md border`} />

                </div>
                <div className="flex flex-col w-full px-4 py-2 gap-2 rounded-md shadow-md border">
                    <p className='font-satoshi text-[16px] font-semibold'>Work</p>
                    <input readOnly={editprofile}
                 onChange={(e) => setWorkAddress(e.target.value)}
                 placeholder='Work address'
                  type="text" name="" id="" value={work_address} className={`${editprofile? 'border-white':'border-[#FF6600]'} font-satoshi w-full px-2 py-2 outline-none rounded-md border`} />

                </div>
                <div className="flex flex-col w-full px-4 py-2 gap-2 rounded-md shadow-md border">
                    <p className='font-satoshi text-[16px] font-semibold'>Delivery</p>
                    <input readOnly={editprofile}
                 onChange={(e) => setDefaultAddress(e.target.value)}
                 placeholder='Default delivery address'
                  type="text" name="" id="" value={default_address} className={`${editprofile? 'border-white':'border-[#FF6600]'} font-satoshi w-full px-2 py-2 outline-none rounded-md border`} />

                </div>
            </div>
            <div className="flex flex-col w-full">
            <label className='font-satoshi text-[14px]'>Phone number</label>
                 <input readOnly={editprofile}
                 onChange={(e) => setPhoneNumber(e.target.value)}
                 placeholder='Phone number'
                  type="text" name="" id="" value={phone_number} className={`${editprofile? 'border-white':'border-[#FF6600]'} font-satoshi w-full px-4 py-2 outline-none rounded-md border`} />
            </div>
                <div className="flex flex-row w-full">
                    {/* <button className=' font-satoshi px-4 py-2 rounded-md bg-gray-200 text-center'>Add address</button> */}
                </div>
            </div>
        </div>
        
    </div>
    </div>

    <ToastContainer />
    </>
  )
}

export default AccountDetails