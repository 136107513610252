import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";


function ModalLayout({setToggleModal, ...props}) {
       
  return (
    <>
    <div
    onClick={() => setToggleModal(false)}
    className='fixed h-screen flex justify-center items-center inset-0 bg-gray-900  bg-opacity-50 dark:bg-opacity-80 z-[999] overflow-auto px-2 md:px-0'>
    <div
        onClick={(e) => e.stopPropagation()}
        className='relative w-full max-w-lg bg-white rounded-lg py-4 px-4 md:px-8 z-50 flex flex-col gap-2'>
        <div className={`flex  ${props.title ? 'justify-between' : "justify-end"}`}>
            <div className='flex gap-2 items-center'>

                {props.title && (
                    <p className='font-semibold text-lg'>{props.title}</p>
                )}
            </div>
            <div 
             onClick={() => setToggleModal(false)}
            className="cursor-pointer">
              <AiOutlineCloseCircle size={25}/>
            </div>
        </div>
        {props.children}
    </div>
</div>
</>
  )
}

export default ModalLayout