import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { addToCart } from '../features/cart/cartSlice'
import { ENDPOINT_CATEGORY, ENDPOINT_PRODUCTS } from '../routes'
import { BsQuestionCircleFill } from "react-icons/bs";
import ModalLayout from "./common/ModalLayout"
import PriceEnquiryModal from './common/PriceEnquiryModal'
function Product({id, prod, name, category, old_price, new_price, image_url, image_urls}) {
    const [openModal, setOpenModal] = useState(false)

    const handleClose = () => {
      setOpenModal(false)
    }

    const dispatch = useDispatch()

    const handleAddToCart = (product) =>{
        dispatch(addToCart(product))

    }
    const discount = Math.round(100 * ((old_price - new_price) / old_price))
  return (
    <>
    {openModal && <ModalLayout open={openModal} setToggleModal={handleClose} title="Enquire price">
      <PriceEnquiryModal product={prod} handleClose={handleClose}/>
    </ModalLayout>}
    <div className='bg-white relative flex flex-col w-full h-full py-2 shadow-md'>
    {!prod?.enquire &&<>
        {old_price > 0 ? 
            <div className="absolute top-4 right-0 alert_wrap bg-[#FF6600] text-white px-2 py-1 alert_wrap text-[13px]  font-satoshi">
                {discount + '% OFF'}
            </div>
            :
            null 
            } 
        </>}
        <img src={image_url?image_url:image_urls?image_urls[0]:''} alt="" className='h-full md:h-[20rem] w-full mx-auto object-cover' />
        <div className="justify-center text-center font-satoshi flex flex-col gap-1">

        <Link to={`${ENDPOINT_PRODUCTS}/${id}`}>
        <p className="font-satoshi text-center text-gray-600 text-[18px] cursor-pointer ">
            {name}
        </p>
        </Link> 
        <div className="border-b border-gray-200"></div>
        <Link to={`${ENDPOINT_CATEGORY}/${category.id}`}>
        <p className="font-satoshi text-gray-600 text-[18px] cursor-pointer ">
            {category.name}
        </p>
            </Link>
        <div className="flex justify-center text-center flex-row gap-1">
        {(old_price >0 && !prod?.enquire) &&<p className="font-satoshi text-gray-600 line-through text-[12px] cursor-pointer ">
            ${old_price}
        </p>}
          {!prod?.enquire &&<p className="font-satoshi text-gray-600 text-[16px] cursor-pointer ">
            ${new_price}
        </p>}
        {old_price > 0 ? 
        <p className="font-satoshi text-[#FF6600] text-[12px] cursor-pointer ">
            
            ({discount}%)
        </p>
        :
        null
        }

        </div>
        {!prod?.enquire &&
        <div
        onClick={() => handleAddToCart(prod)} 
         className="flex flex-row gap-2 px-2 justify-center items-center cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-[#FF6600]">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>

        <p
    
        className="font-satoshi text-[#FF6600] text-[14px] font-medium ">
            Add to cart
        </p>
        </div>}
        </div>
        {prod?.enquire &&
        <div onClick={() => setOpenModal(true)} className="">
            <p className='font-satoshi cursor-pointer text-gray-400 text-[14px] flex flex-row items-center gap-1'>Enquire product's price
                <span>
                <BsQuestionCircleFill className='text-gray-300'/>
                </span>
            </p>
        </div>}
    </div>
    </>
  )
}

export default Product
