import React from 'react'
import Signup from './Signup'
import SignIn from './SignIn'

const OnBoarding = ({open, setOnBoarding, reg,setReg}) => {
  return (
    <>
    {reg ?
    <Signup setReg={setReg} open={open} setOnBoarding={setOnBoarding}/>
    :
    <SignIn setReg={setReg} open={open} setOnBoarding={setOnBoarding}/>
    }

    </>
  )
}

export default OnBoarding