import React, { useState } from 'react'
import TopBar from '../components/shared/TopBar'
import Hero from '../components/Hero'
import CategoriesRow from '../components/CategoriesRow'
import LandingProduct from '../components/landing/LandingProduct'
import FeaturedProducts from '../components/landing/FeaturedProducts'
import Offers from '../components/landing/Offers'
import RevFooter from '../components/shared/RevFooter'
import ProductCard from '../components/landing/ProductCard'
import { Link } from 'react-router-dom'
import AdvertGrid from '../components/landing/AdvertGrid'
import { MdArrowRightAlt } from "react-icons/md"
import { ENDPOINT_PRODUCTS } from '../routes'
import Carousel from '../components/landing/Carousel'
import CrazyOffers from '../components/landing/CrazyOffers'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetBestSellerMainProductsQuery, useGetBestSellerProductsQuery, useGetFeaturedProductsQuery, useGetLatestProductsQuery, useGetOnOfferProductsQuery } from '../features/products/productsApiSlice'
import LandingGrid from '../components/landing/LandingGrid'

const LATEST_TAB = 'Latest'
const OFFER_TAB = 'Offers'
const FEATURED_TAB = 'Featured'
const BEST_TAB = 'Best selling'

const Landing = () => {
  const { data: onOfferProducts, isLoading:fetching } = useGetOnOfferProductsQuery()
  const { data: featuredproducts, isLoading } = useGetFeaturedProductsQuery()
  const { data: latestproducts, isLoading:processing } = useGetLatestProductsQuery()
  const { data: mainBestSeller, isLoading:loading } = useGetBestSellerMainProductsQuery()
  const { data: allBestSeller, isLoading:querying } = useGetBestSellerProductsQuery()

  const [activeTab, setActiveTab] = useState(LATEST_TAB)

  // best selling
  // best selling 6
  // all featured latest best selling

  return (
    <div className="flex flex-col w-full gap-8 font-satoshi">

    <div className="flex flex-col gap-2 relative">

    <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[70vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
            <Hero/>
        </div>
        
    </div>
    <div className="container mx-auto absolute -bottom-48 md:-bottom-24 px-2 left-1/2 transform -translate-x-1/2">
      <CategoriesRow/>
    </div>
 
    </div>
    <div className="flex flex-col w-full gap-20 px-2 container mt-32 mx-auto py-20">
    <div className="container mx-auto w-full">
    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
      <div className="">
      <div className="relative h-full md:h-[24rem] rounded">
      {/* Image */}
      <img
        src="https://wildinafrica.store/cdn/shop/products/2022BIG5STACK1000px.png?v=1657615152"
        alt="Your Image"
        className="w-full md:w-auto h-full md:h-96 mx-auto rounded overflow-hidden"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-25 rounded"></div>

      {/* Text */}

      <div className="absolute inset-0 flex flex-col items-end">
        
        <div className="flex py-4">

        {/* <div className="bg-black text-center px-3 py-1.5 text-white uppercase font-semibold">
            up to 30% off
        </div> */}
        </div>
        <div className="flex flex-col items-end w-full px-2 gap-1 text-white md:text-gray-900 ">
            <p className='font-semibold uppercase'>mineral crafted</p>
            <p className='font-bold text-2xl uppercase'>bRACELETS</p>
            <div className="">

            <Link to={ENDPOINT_PRODUCTS} className='bg-[#FF780C] px-3 rounded-md py-1.5 flex flex-row items-center gap-2 justify-center'>
                <p className='text-white text-[14px]'>Shop now</p>
                <MdArrowRightAlt className='text-white' size={20}/>
            </Link>
            </div>
        </div>
      </div>
    </div>
      </div>
      <div className="">
      <div className="relative h-full md:h-[24rem] rounded">
      {/* Image */}
      <img
        src="https://purepng.com/public/uploads/large/african-drum-zig.png"
        alt="Your Image"
        className="w-full md:w-auto mx-auto h-full md:h-96 rounded overflow-hidden"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-25 rounded"></div>

      {/* Text */}

      <div className="absolute inset-0 flex flex-col items-end">
        
        <div className="flex py-4">

        {/* <div className="bg-black text-center px-3 py-1.5 text-white uppercase font-semibold">
            up to 30% off
        </div> */}
        </div>
        <div className="flex flex-col items-end w-full px-2 gap-1 text-grayu-900">
            <p className='font-semibold uppercase'>SPIRIT LIFTING</p>
            <p className='font-bold text-3xl uppercase'>dRUMS</p>
            <div className="">

            <Link to={ENDPOINT_PRODUCTS} className='bg-[#FF780C] px-3 rounded-md py-1.5 flex flex-row items-center gap-2 justify-center'>
                <p className='text-white text-[14px]'>Shop now</p>
                <MdArrowRightAlt className='text-white' size={20}/>
            </Link>
            </div>
        </div>
      </div>
    </div>
      </div>
    </div>
    </div>
    {latestproducts?.length > 0 &&
    <Carousel title="Latest" products={latestproducts}/>
  
    }
    {isLoading &&
        <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
</div>
    }
    {onOfferProducts?.length > 0 &&
    <CrazyOffers title="On Offer" products={onOfferProducts}/>
    }
    {fetching &&
    <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>
    <Skeleton height={100}/>

    </div>
    }

    {featuredproducts?.length > 0 &&
    <Carousel title="Featured" products={featuredproducts}/>
    }
        {processing &&
        <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
</div>
    }

      <div className="container mx-auto w-full">
      <div className="">
      <div className="relative h-full md:h-[24rem] rounded">
      {/* Image */}
      <img
        src="https://ke.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/39/896585/2.jpg?6267"
        alt="Your Image"
        className="w-full object-contain object-right items-end mx-auto h-full rounded overflow-hidden"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-60 md:opacity-25 rounded"></div>

      {/* Text */}

      <div className="absolute inset-0 flex flex-col  w-[75%]">
        
        <div className="flex py-3 md:py-8 px-4 flex-col gap-4">
          {/* <p className='uppercase text-[14px] md:text-[18px] text-white md:text-gray-800 font-semibold'>uP to <span className='ml-1 text-[#FF780C]'>20% off</span></p> */}

        <div className="flex flex-col  w-full gap-1 justify-center text-white md:text-gray-900">
            <p className='font-semibold text-[16px] md:text-2xl capitalize'>Save An extra 50%</p>
            <p className='text-[12px] md:text-[18px]'>Discover the rich tapestry of African culture through our curated collection of vibrant and authentic African art. Each piece tells a unique story, bringing the spirit and beauty of Art into your home.</p>
        </div>
            <div className="flex">

            <Link to={ENDPOINT_PRODUCTS} className='bg-gray-900 px-3 rounded-md py-1.5 flex flex-row items-center gap-2 justify-center'>
                <p className='text-white text-[14px]'>Shop now</p>
                <MdArrowRightAlt className='text-white' size={20}/>
            </Link>
            </div>
        </div>
      </div>
    </div>
      </div>
      </div>
      {allBestSeller?.length > 0 &&
    <Carousel title="Best selling"  products={allBestSeller}/>
    }

    {querying &&
        <div className="grid grid-cols-1 md:grid-cols-6 gap-2">
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
        <Skeleton height={300}/>
      </div>
    }
      {/* <FeaturedProducts title="Best selling"/> */}


    </div>
    <AdvertGrid products={mainBestSeller} loading={loading}/>
    <div className="flex container px-2 mx-auto w-full flex-col py-8">


    <div className="flex flex-row w-full gap-4 border-b-2 border-[#FFEFE4] px-2">
      <div onClick={() => setActiveTab(LATEST_TAB)} className={`  hover:text-[#FF780C] hover:underline underline-offset-4 decoration-4 font-semibold text-[18px] cursor-pointer ${activeTab === LATEST_TAB ? 'text-[#FF780C] underline underline-offset-4 decoration-4':'text-gray-700'}`}>{LATEST_TAB}</div>
    <div onClick={() => setActiveTab(OFFER_TAB)} className={`  hover:text-[#FF780C] hover:underline underline-offset-4 decoration-4 font-semibold text-[18px] cursor-pointer ${activeTab === OFFER_TAB ? 'text-[#FF780C] underline underline-offset-4 decoration-4':'text-gray-700'}`}>{OFFER_TAB}</div>
      <div onClick={() => setActiveTab(FEATURED_TAB)} className={`  hover:text-[#FF780C] hover:underline underline-offset-4 decoration-4 font-semibold text-[18px] cursor-pointer ${activeTab === FEATURED_TAB ? 'text-[#FF780C] underline underline-offset-4 decoration-4':'text-gray-700'}`}>{FEATURED_TAB}</div>
      <div onClick={() => setActiveTab(BEST_TAB)} className={`  hover:text-[#FF780C] hover:underline underline-offset-4 decoration-4 font-semibold text-[18px] cursor-pointer ${activeTab === BEST_TAB ? 'text-[#FF780C] underline underline-offset-4 decoration-4':'text-gray-700'}`}>{BEST_TAB}</div>
      </div>
      {activeTab === LATEST_TAB ?
      <LandingGrid products={latestproducts} loading={processing}/>
      :activeTab === OFFER_TAB ?
      <LandingGrid products={onOfferProducts} loading={fetching}/>
      :activeTab === FEATURED_TAB ?
      <LandingGrid products={featuredproducts} loading={isLoading}/>
      :activeTab === BEST_TAB ?
      <LandingGrid products={allBestSeller} loading={querying}/>
      :
      null
}
      <>
      </>
    
    </div>
    <RevFooter/>
    </div>
  )
}

export default Landing