import React from 'react'

function Pagination({currentPage, totalPages, fetchNextPage, fetchPreviousPage, fetchFirstPage, fetchLastPage}) {
    
  return (
    <>
    <div className="flex flex-row gap-2">
        <div 
        onClick={fetchFirstPage}
        className="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:text-[#FF6600] w-4 mt-0.5 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
        </svg>

        </div>
        <div 
        onClick={fetchPreviousPage}
        className="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:text-[#FF6600] w-4 mt-0.5 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>

        </div>
        <div className="flex flex-row gap-2">
            <p className="font-satoahi text-[14px] font-semibold">
                {currentPage}
            </p>
            <p className="font-satoahi text-[14px] ">
                of
            </p>
            <p className="font-satoahi text-[14px] font-semibold">
                {totalPages}
            </p>
        </div>
        <div 
        onClick={fetchNextPage}
        className="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:text-[#FF6600] w-4 mt-0.5 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>

        </div>
        <div 
        onClick={fetchLastPage}
        className="cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="hover:text-[#FF6600] w-4 mt-0.5 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
        </svg>

        </div>

    </div>
    </>
  )
}

export default Pagination