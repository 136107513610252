import React from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_PRODUCTS } from '../routes'

const Hero = () => {
  return (
    <div className='w-full font-satoshi grid grid-cols-1 md:grid-cols-2 gap-6 place-items-center px-2 py-2 h-full'>
        <div className="flex flex-col justify-center h-full w-full gap-4 ">
            <div className="flex flex-col gap-2">
            <p className='font-bold text-gray-900 text-2xl md:text-5xl'><span className='text-indigo-500'>Unique</span> </p>
            <div className="bg-indigo-500 h-1 w-[10%]"></div>
            <p className='font-bold text-gray-700 text-2xl md:text-5xl'><span>Contemporary Art  Gallery</span></p>

            </div>
            <p className=' text-gray-700 text-[16px] md:text-xl'>"Discover the rich tapestry of African culture through our curated collection of vibrant and authentic African art. Each piece tells a unique story, bringing the spirit and beauty of Art into your home."</p>
            <div className="">

            <Link to={ENDPOINT_PRODUCTS} className='bg-indigo-500 px-6 py-2 rounded-sm text-center text-white tex-[16px]'>Shop</Link>
            </div>
        </div>
        <div className="hidden md:block w-full">
          <img src="https://5.imimg.com/data5/SELLER/Default/2022/1/OW/PV/UB/130824861/elephant-family-statue-500x500.png" className='drop-shadow-2xl' alt="" />
        </div>
    </div>
  )
}

export default Hero