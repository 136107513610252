import React, { useState } from 'react'
import { MdMarkEmailRead } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { IoMdCall } from "react-icons/io";
import { toast } from 'react-toastify';
import axios from 'axios';



const PriceEnquiryModal = ({product,handleClose}) => {
  const [openSms, setOpenSms] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')
  const link = `https://www.uzuricraft.com/products/${product?.id}`
  const message = `Price enquiry for ${product?.name} LINK:${link}`
  console.log(message)
  const baseUrl = 'https://staging--api-enthusiastic.up.railway.app/api/v1/send-price-enquiry/email/'
  
  const handleSendEnquiry = async () => {
    setLoading(true)
    const payload = {
      email,
      message
    }
    try {
      const response = await axios.post(`${baseUrl}`, payload);
      toast.success('Your enquiry was successfully submitted. Our sales representative will get back to you.')
      setLoading(false)
      handleClose()
      setEmail("")
    } catch (error) {
      console.log(error);
      toast.error('An error occured. Please try again.')
      setLoading(false)

    }
  }

  
  


  return (
    <div className='w-full flex flex-col gap-2'>
      <a href='tel:+14349220285' className="flex flex-row items-center gap-2 cursor-pointer">
      <IoMdCall size={30} className='text-gray-500'/>
      <p className='text-gray-500 text-[14px]'>Enquire through direct call</p>
      </a>
      <div onClick={() => setOpenSms(!openSms)} className="flex flex-row items-center gap-2 cursor-pointer">
      <AiOutlineMessage size={30} className='text-gray-500'/>
      <p className='text-gray-500 text-[14px]'>Enquire through sms</p>
      </div>
      {openSms &&<div className="">
        <p className='text-gray-500 text-[14px]'>+1 (434) 922-0285</p>
        </div>}
      <div onClick={() => setOpenEmail(!openEmail)} className="flex flex-row items-center gap-2 cursor-pointer">
      <MdMarkEmailRead size={30} className='text-gray-500'/>
      <p className='text-gray-500 text-[14px]'>Enquire through email</p>
      </div>
      {openEmail &&<div className="flex flex-col gap-1">
        <label htmlFor=""  className='text-gray-500 text-[14px]'>Your email</label>
        <input type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
         className='outline-none rounded-md px-2 py-1.5 focus:outline-none border' />
        {email && email.length >= 5 && <div className="">
          <button onClick={handleSendEnquiry} disabled={loading} className='rounded-md text-center px-4 py-2 font-satoshi text-[14px] text-white bg-indigo-500'>{loading?"Sending...":"Send enquiry email"}</button>
        </div>}
        </div>}

    </div>
  )
}

export default PriceEnquiryModal