import React, { useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';

// google services

const GoogleMapInput = ({ setLocation, required, handleDeliveryLacation }) => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [area, setArea] = useState(null);
    const [locationPay, setLocationPay] = useState(null);

    const latitudeShop = 37.239375;
    const longitudeShop = -80.020054
    
    const source = { lat: latitudeShop, lon: longitudeShop }
    // const [destination, setDestination] = useState({ lat: 0, lon: 0 });
    const [distance, setDistance] = useState(0);
    const [cost, setCost] = useState(0);


    const pricePerMile = 0.1;  // in dollars

      const calculateDistance = (source, destination) => {
        // const earthRadius = 6371; // in kilometers
        const earthRadius = 3956;  // in miles
      
        const lat1Rad = toRadians(source.lat);
        const lat2Rad = toRadians(destination.lat);
        const lon1Rad = toRadians(source.lon);
        const lon2Rad = toRadians(destination.lon);
      
        const deltaLat = lat2Rad - lat1Rad;
        const deltaLon = lon2Rad - lon1Rad;
      
        const a =
          Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
          Math.cos(lat1Rad) *
            Math.cos(lat2Rad) *
            Math.sin(deltaLon / 2) *
            Math.sin(deltaLon / 2);
      
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;
      
        return distance;
      }
      
      const toRadians = (degrees) => {
        return degrees * (Math.PI / 180);
      }  


    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyDxRfnngmU4fU37zfBCbC6EtpZo7lSXSA4",
        libraries: ['places'],
        options: {
            // types: ['establishment'],
            types: ['address'],
            componentRestrictions: { 'country': "us" },
            fields: ['place_id', 'geometry', 'name', 'address_components', 'icon', 'formatted_address'],
            strictBounds: false,
        },
        onPlaceSelected: (place) => {
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            setLatitude( place.geometry.location.lat())
            setLongitude(place.geometry.location.lng())
            const destination = { lat: lat, lon: lng }
            const distance = Math.round(calculateDistance(source, destination));
            const totalCost = distance * pricePerMile
            setCost(parseInt(totalCost))
            setDistance(distance);  

            const placeName = place.name
            setArea(placeName)
            const locationPayload = {
                lat: lat,
                lng: lng,
                placeName: placeName,
                icon: place?.icon,
                others: place?.address_components
            }
            // handleCalculateDistance()
            setLocationPay(place)
        }
    })

    handleDeliveryLacation(area,latitude, longitude, locationPay, distance, cost)

    // const handleCalculateDistance = () => {
    //     const distance = calculateDistance(source, destination);
    //     setDistance(distance);
    //   };



    // const handleSelec

    const navigate = useNavigate()

  const handleNext = () => {
    const queryParams = `?lat=${latitude}&lng=${longitude}`;
  };




    return (
        <>
        <form>

        <input
            required={required}
            ref={ref}
            type="text" name="locationAutocomplete" id="locationAutocomplete"
            placeholder='Enter delivery address'
            className='font-satoshi w-full px-2 py-2 border outline-none rounded-md'
        />
        </form>
    
        </>
    )
}

export default GoogleMapInput