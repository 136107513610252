import { apiSlice } from "../../app/api/apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            // query: (queryParams) => `/products/?${queryParams}`,
            query: ({page,date, lp,hp,cat,search}) => `/products/?sort=${date}&lp=${lp}&hp=${hp}&cat=${cat}&q=${search}&page=${page}&page_size=10`,


            // providesTags:['Products']
        }),
        getSingleProduct: builder.query({
            query: (id) => `/products/${id}`,
            // providesTags:['Products']

        }),
        // featured products
        getFeaturedProducts: builder.query({
            query: () => '/products/featured',
            // providesTags:['Products']
        }),
        // on offer products
        getOnOfferProducts: builder.query({
            query: () => '/products/offer',
            // providesTags:['Products']
        }),
        // Best seller six
        getBestSellerMainProducts: builder.query({
            query: () => '/products/best-seller-six',
            // providesTags:['Products']
        }),
        // Best seller
        getBestSellerProducts: builder.query({
            query: () => '/products/best-seller',
            // providesTags:['Products']
        }),
        // latest products
        getLatestProducts: builder.query({
            query: () => '/products/latest',
            // providesTags:['Products']
        }),

        getProductsSearchResult: builder.query({
            query: ({page, searchTerm,date, price }) => `/products/search/?search=${searchTerm}&sort=${date},${price}&page=${page}&page_size=10`,

            // providesTags:['Products']
        }),

        
    }),
    
})


export const { 
    useGetSingleProductQuery,
    useGetProductsQuery,
    useGetFeaturedProductsQuery,
    useGetLatestProductsQuery,
    useGetProductsSearchResultQuery,
    useGetOnOfferProductsQuery,
    useGetBestSellerMainProductsQuery,
    useGetBestSellerProductsQuery,
    
} = productsApiSlice