import React from 'react'
import SingleProduct from '../components/SingleProduct'
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'

function SingleProductPage() {
  return (
    <>
        <div className="flex flex-col w-full">
          <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
    <div className="container mx-auto w-full min-h-[70vh] flex flex-col justify-center">

    <SingleProduct />
    </div>
    <RevFooter/>
    </div>
    </>
  )
}

export default SingleProductPage