import React from 'react'
import Product from './Product'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProductsWithoutResults({products, isLoading}) {
  return (
    <div className="flex flex-col gap-2">

    <div className='w-full flex md:grid flex-col md:grid-cols-5 gap-2'>



  
        {isLoading ?
        <>
            <Skeleton height={500} />
            <Skeleton height={500}/>
            <Skeleton height={500}/>
            <Skeleton height={500}/>
            <Skeleton height={500}/>
        </>
        :
        <>

        {products?.map((product) => (
            <div key={product.id} className="col-span-1 h-full w-full">
                <Product
                prod={product}
                id={product?.id}
                name={product?.name}
              image_urls = {product?.images_urls}
                category={product?.category}
                old_price={product?.old_price}
                new_price={product?.new_price}
                image_url={product?.thumbnail_url} 
                />
            </div>
        ))}
        </>
        }
    </div>
    </div>
  )
}

export default ProductsWithoutResults