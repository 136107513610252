import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetBasketQuery } from '../features/auth/authenticationSlice'
import { useEditOrderMutation } from '../features/categories/categoriesApiSlice'
import { ThreeDots } from 'react-loader-spinner'
import TopBar from '../components/shared/TopBar'
import RevFooter from '../components/shared/RevFooter'




function TrackOrder() {
    const { orderID } = useParams()
    const navigate = useNavigate()

   

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const day = getOrdinalDay(date.getDate());
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        const hour = padZeroes(date.getUTCHours());
        const minute = padZeroes(date.getMinutes());
        return `${day} ${month} ${year} ${hour}.${minute}`;
      };

      const getOrdinalDay = (day) => {
        if (day > 3 && day < 21) return `${day}th`;
        switch (day % 10) {
          case 1:
            return `${day}st`;
          case 2:
            return `${day}nd`;
          case 3:
            return `${day}rd`;
          default:
            return `${day}th`;
        }
      };
    
      const padZeroes = (number) => {
        return number.toString().padStart(2, "0");
      };
    const { data: order, isLoading, refetch} = useGetBasketQuery(orderID)

    const [ editOrder, {isLoading:loading} ] = useEditOrderMutation()
    // console.log('order',order)

    const orderProcessing =order?.stage === 'Processing'
    const orderProcessed =order?.stage === 'Processed'
    const orderConfirmed =order?.stage === 'Confirmed'
    const orderDispatched =order?.stage === 'Dispatched'
    const orderTransit =order?.stage === 'Transit'
    const orderArrived =order?.stage === 'Arrived'
    const orderCompleted =order?.stage === 'Completed'
    const orderCancelled =order?.stage === 'Cancelled'
    
    const newStage = 'Completed'

    const handleSubmit = async () => {
        
        
        editOrder({statusData:{stage:newStage},id :orderID})
        .unwrap()
        .then((payload) => {
        //   console.log('fulfilled', payload)
          // window.location.reload()
        
         refetch()
          
        })
        .catch((error) => {
          console.error('rejected', error)}
          );
        
        
        
        }


  return (
    <>
              <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>
    {isLoading?
        <p className='font-satoshi text-[16px]'>Loading...</p>
        :
    <div className='font-satoshi flex flex-col gap-2 container mx-auto py-10 px-2'>
        <div className="flex flex-row">
            <button
            onClick={()=> navigate(-1)}
             className='flex flex-row justify-center items-center gap-1 text-[14px] bg-gray-200 rounded-md px-4 py-1'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                </svg>

                Back
            </button>
        </div>
        <div className="flex flex-row justify-between ">

        <div className="flex flex flex-col md:flex-row gap-2">
            <div className="flex flex-row">

            <p className='uppercase text-[18px] font-semibold'>ORDER #</p>
            <p className='uppercase text-[18px] font-semibold'>EART-{order?.id}</p>
            </div>
            <div className="flex flex-row gap-2">
            <p className='Capitalize text-[16px] text-gray-400'>{formatDateTime(order?.created)}</p>


            </div>
            <div className="flex flex-row gap-2">

            <p className=' text-[16px] font-semibold'>$ {order?.amount_paid}</p>
            <p className=' text-[16px] font-medium text-gray-600'>{order?.lines?.length}({order?.lines?.length ===1? 'Item':'Items'})</p>
            </div>



        </div>
        <div className="">

        <button className={`${order?.stage === 'Processing'? 'bg-[#FCB28E]'
                  :order?.stage ==='Processed'?  'bg-[#F0A6A5]'
                  :order?.stage ==='Confirmed'?  'bg-[#54D3CA]'
                  :order?.stage ==='Dispatched'?  'bg-[#8CD1FC]'
                  :order?.stage ==='Transit'?  'bg-[#3E5DDD]'
                  :order?.stage ==='Arrived'?  'bg-[#B9E38D]'
                  :order?.stage ==='Completed'?  'bg-[#059D5F]'
                  :order?.stage ==='Cancelled'?  'bg-[#DE6972]'
                  :
                  'bg-gray-200'
            } px-4 py-2 text-[14px] text- font-satoshi`}>{order?.stage}</button>
        </div>
        </div>
        {/* <div className="flex flex-col gap-2">
            {order?.lines?.map((line) => (
                <>
            <p className=' px-2 py-2.5 shadow-md border rounded-sm text-[16px] font-medium text-gray-600'>( {line?.quantity} ) {line?.product?.name}</p>
                </>

            ))}
        </div> */}
        {orderArrived && !orderCompleted &&
        <div className="flex flex-row-reverse">
            <button onClick={handleSubmit} className='font-satoshi text-[14px] px-4 py-2 rounded-md bg-gray-200'> 
            {loading?
            <ThreeDots 
                height="20" 
                width="60" 
                radius="9"
                color="#FF6600" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName="justify-center items-center flex"
                visible={true}
                /> 
                :
                'Received?' }
            </button>
        </div>
        }
        {!orderCancelled?
        <div className="flex flex-col">
            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2 ${orderProcessing || orderProcessed || orderConfirmed || orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}  rounded-full p-4`}></div>
                    <div className={`w-1 h-16 ${orderProcessing || orderProcessed || orderConfirmed || orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'} border-2 border-r justify-center items-center`}></div>
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>{orderProcessing? 'Processing':'Processed'}</p>

            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2  rounded-full p-4 ${orderConfirmed || orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                    <div className={`w-1 h-16 border-2 border-r  justify-center items-center ${orderConfirmed || orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>Confirmed</p>

            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2 rounded-full p-4 ${orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                    <div className={` ${orderDispatched || orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'} w-1 h-16 border-2 border-r  justify-center items-center`}></div>
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>Dispatched</p>

            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2  rounded-full p-4  ${orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                    <div className={`w-1 h-16 border-2 border-r  justify-center items-center ${orderTransit ||  orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mt-1 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                </svg>

            <p className=' text-[16px] font-medium text-gray-600 mt-1'>On the way</p>

            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2 rounded-full p-4 ${orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                    <div className={`w-1 h-16 border-2 border-r  justify-center items-center ${orderArrived ||  orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>Arrived</p>

            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2  rounded-full p-4 ${orderCompleted ? 'border-blue-500 bg-blue-500':'border-gray-200 bg-gray-200'}`}></div>
                    {/* <div className="w-1 h-16 bg-gray-600 border-2 border-r border-gray-600 justify-center items-center"></div> */}
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>Completed</p>

            </div>
        </div>
        :
        <div className="flex flex-col">
        <div className="flex flex-row gap-2">
            <div className="flex flex-col justify-center items-center">
                <div className={` border-2 border-gray-200 bg-gray-200 rounded-full p-4`}></div>
                <div className={`w-1 h-16 border-gray-200 bg-gray-200 border-2 border-r justify-center items-center`}></div>
            </div>
        <p className=' text-[16px] font-medium text-gray-600 mt-1'>Processed</p>

        </div>
        <div className="flex flex-row gap-2">
                <div className="flex flex-col justify-center items-center">
                    <div className={` border-2  rounded-full p-4 border-red-500 bg-red-500`}></div>
                    {/* <div className="w-1 h-16 bg-gray-600 border-2 border-r border-gray-600 justify-center items-center"></div> */}
                </div>
            <p className=' text-[16px] font-medium text-gray-600 mt-1'>Canelled</p>

            </div>
        </div>


        }
    </div>
    }
    <RevFooter/>
    </>
  )
}

export default TrackOrder