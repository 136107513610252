import { createSlice } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const initialState = {
    cartItems: localStorage.getItem('cartItems')
     ? JSON.parse(localStorage.getItem('cartItems')) 
     : [],
    // total num of products in cart
    cartTotalQuantity: 0,
    // cart total
    cartTotalAmount: 0,


}

const cartSlice = createSlice({
    name:'cart',
    initialState,
    reducers :{
        addToCart(state, action){
            // check if item is already in cart by comparing all items in the cart with the curren action.payload
            // (state.cartItems.findIndex((item) => item.id === action.payload.id)) this returns an index
            // if item is already in cart itemIndex will be 0 or a number greater than 0 else it will be -1
            const itemIndex = state.cartItems.findIndex((item) => item.id === action.payload.id)

            if(itemIndex >=0 ){
                state.cartItems[itemIndex].cartQuantity += 1
                toast.info(`${action.payload.name} cart quantity increased`,
                {
                  position: "bottom-center",
                })
            }else{

                const tempProduct = { ...action.payload, cartQuantity:1}
                state.cartItems.push(tempProduct)
                toast.success(`${action.payload.name} added to cart`,
                {
                  position: "bottom-center",
                })
            }

            localStorage.setItem('cartItems', JSON.stringify(state.cartItems))

        },

        removeFromCart(state, action){
            // returns array of items whose ids are not equal to the action.payload.id (in simple terms it means exclude the item whose id matches the action.payload.id)
            const nextCartItems = state.cartItems.filter((cartItem) => cartItem.id !== action.payload.id)
            state.cartItems = nextCartItems
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
            toast.error(`${action.payload.name} removed from cart`,
            {
              position: "bottom-center",
            })

            

        },
        decreaseCartQuantiy(state, action){
            const itemIndex = state.cartItems.findIndex((cartItem) => cartItem.id === action.payload.id)
            // if qty is greater than 1 decrease else if === 0 remove it
            if (state.cartItems[itemIndex].cartQuantity > 1){
                state.cartItems[itemIndex].cartQuantity -= 1
                toast.info(`${action.payload.name} quantity decreased`,
                {
                position: "bottom-center",
                })

            } else if (state.cartItems[itemIndex].cartQuantity === 1){
                const nextCartItems = state.cartItems.filter((cartItem) => cartItem.id !== action.payload.id)
                state.cartItems = nextCartItems
                toast.error(`${action.payload.name} removed from cart`,
                {
                    position: "bottom-center",
                })
                
            }
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems))

        },
        clearCart(state,action){
            state.cartItems = []
            toast.error(`Cart cleared`,
            {
                position: "bottom-center",
            })
            localStorage.setItem('cartItems', JSON.stringify(state.cartItems))


        },
        getTotals(state, action){
            let { total, quantiy } = state.cartItems.reduce((cartTotal, cartItem)=> {
                const {new_price, cartQuantity } = cartItem
                const itemTotal =  new_price * cartQuantity

                cartTotal.total += itemTotal
                cartTotal.quantiy += cartQuantity

                return cartTotal
            }, {
                total:0,
                quantiy:0
            })
            state.cartTotalQuantity = quantiy
            state.cartTotalAmount = total
            localStorage.setItem('cartTalQuantity', JSON.stringify(state.cartTotalQuantity))
            localStorage.setItem('cartTotalAmount', JSON.stringify(state.cartTotalAmount))
        },


    }
})

export const { addToCart, removeFromCart, decreaseCartQuantiy, clearCart, getTotals } = cartSlice.actions

export default cartSlice.reducer