import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import CategoryCard from './landing/CategoryCard'
import { useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CategoriesRow = () => {
  const { data: categories, isLoading } = useGetCategoriesQuery()
  return (
    <div className="flex flex-col w-full bg-white shadow-md p-3 z-40 font-satoshi border-2 border-dashed rounded">
        <p className="text-gray-600 font-semibold text-[20px]">Categories</p>
        <div className="bg-indigo-500 h-1 w-[10%]"></div>
        <div className="flex flex-row gap-4 overflow-x-scroll w-full custom-scrollbar py-2">
           
             
        {isLoading ?
        <>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
            <Skeleton height={70}/>
        </>
        :
        <>
      {categories.length > 0 &&
        categories.map((category) => (

            <CategoryCard key={category?.id} category={category}/>
        ))}
        </>

        }


        </div>
    </div>
  )
}

export default CategoriesRow