import React from 'react'
import { useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { ENDPOINT_CATEGORY } from '../routes'
import { Link, useNavigate } from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'

function Advert() {
    const { data: categories } = useGetCategoriesQuery()
    const navigate = useNavigate()

  return (
    <div className='w-full  h-40 gap-2 bg-white hide-scrollbar flex  overflow-x-scroll'>
      <div className="flex flex-nowrap gap-2">

        {categories?.map((category) => (
            // <div className="relative z-[1] w-full">
            <>
            {/* <Link to={`${ENDPOINT_CATEGORY}/${category.id}`} className='w-full'> */}

                <img
                 id={category.name}
                onClick={() => navigate(`${ENDPOINT_CATEGORY}/${category.id}`)}
                 src={category.thumbnail_url?category.thumbnail_url:''}
                className='w-20 md:w-40 h-20 md:h-40 object-cover rounded-full relative cursor-pointer '
                 alt="" />
                 {/* <p className="absolute px-2 py-1.5 text-white bg-[#FF6600] top-2 left-0 shadow-lg">
                    {category.name}
                 </p> */}
                       <ReactTooltip
                        anchorId={category.name}
                        place="bottom"
                        variant="info"
                        content={category.name}
                      />
            {/* </Link> */}
            </>

                 

              
             
            //      <div className="absolute ribbon h-40 w-40 border-[#FF6600] -top-2 -left-2 overflow-hidden before:-z-[1] before:absolute before:top-0 before:right-0 before:border-4 before:border-[#FF6600] after:absolute after:bottom-0 after:left-0 after:border-4 after:border-[#FF6600] after:-z-[1] px-2 py-0.5 text-white text-[16px] font-satoshi">
            //         <div className="absolute bg-[#FF6600] -left-14 top-[43px] w-60 py-2 text-[13px] text-center shadow-md -rotate-45">
            //             {category.name}
            //         </div>
            //      </div> 
            // </div> 
        ))}

      </div>
  
    </div>
  )
}

export default Advert