import React, { useState } from 'react';
import axios from 'axios';
import { useGetCurrentUserBasketQuery } from '../features/auth/authenticationSlice';

function Payment() {

    // const baseURl = 'http://127.0.0.1:8000/api/v1'
    const baseURl = 'https://staging--api-enthusiastic.up.railway.app/api/v1'


    const { data:userCart } = useGetCurrentUserBasketQuery()

  const [paymentId, setPaymentId] = useState('');
  const [payerId, setPayerId] = useState('');
  const [processing, setProcessing] = useState(false)
  



  const handlePayment = async () => {
    setProcessing(true)
    try {
      const response = await axios.post(`${baseURl}/payment/create/`, {
        basket_id: 1
      });
      setPaymentId(response.data.payment_id);

      // console.log('response.data', response.data)
      setProcessing(false)
      window.location.href = response.data.redirect_url;
    } catch (error) {
      console.log(error);
    }
  }

  const handleExecutePayment = async () => {
    try {
      const response = await axios.post(`${baseURl}/payment/execute/`, {
        payment_id: paymentId,
        payer_id: payerId
      });
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePaymentAndExecute = async () => {
    if (paymentId) {
      await handleExecutePayment();
    } else {
      await handlePayment();
    }
  }

  const handlePayerIdChange = (event) => {
    setPayerId(event.target.value);
  }
  // console.log('userCart',userCart)

  return (
    <div>
      <h1 className='font-satoshi text-[30px]'>PayPal Payment</h1>

      <button className='px-4 py-2 rounded-md font-satoshi tex-[14px] bg-slate-700 text-white' onClick={handlePaymentAndExecute}>{processing? 'Initiating payment...' :'Make Payment'}</button>

    </div>
  );
}

export default Payment;