import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useCreateBasketMutation, useGetCurrentUserBasketQuery, useTransferCartFromLocalstorageToApiMutation } from '../features/auth/authenticationSlice'
import { addToCart, clearCart, decreaseCartQuantiy, getTotals, removeFromCart } from '../features/cart/cartSlice'
import { ENDPOINT_PRODUCTS } from '../routes'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { selectCurrentCart, selectCurrentToken, selectUser, selectUserID } from '../features/auth/authSlice'
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'
import GoogleMapInput from './GoogleMapInput'
import SignIn from './auth/SignIn'
import OnBoarding from './auth/OnBoarding'



function Cart() {
    
    const cart = useSelector((state) => state.cart)
    const currentUseCart = useSelector(selectCurrentCart)
    const dispatch = useDispatch()
    const token = useSelector(selectCurrentToken)
    const userid = useSelector(selectUserID)

    const [open, setOpen] = useState(false)
    const [reg, setReg] = useState(false)
    const [login, setLogin] = useState(false)



    const handleClose = () => {
      setOpen(false)
  }

  




    // const baseUrl = 'http://127.0.0.1:8000/api/v1'
    const baseUrl = 'https://staging--api-enthusiastic.up.railway.app/api/v1'

    // const baseUrl = 'https://staging--api-enthusiastic.up.railway.app/api/v1'

    const [ transferCartFromLocalstorageToApi, { isLoading } ] = useTransferCartFromLocalstorageToApiMutation()
    const [ createBasket ] = useCreateBasketMutation()
    const { data:userCart, refetch } = useGetCurrentUserBasketQuery()



    const [paymentId, setPaymentId] = useState('');
    const [deliveryLocation, setDeliveryLocation] = useState('');

     

    const [deliveryLatitude, setDeliveryLatitude] = useState(null);
    const [deliveryLongitude, setDeliveryLongitude] = useState(null);
    const [location, setLocation] = useState(null);
    const [distance, setDistance] = useState(0);

 

    const [deliveryCost, setDeliveryCost] = useState(0);
    const [orderTotalAmount, setOrderTotalAmount] = useState(cart.cartTotalAmount + deliveryCost);



    const [payerId, setPayerId] = useState('');
    const [cartId, setCartId] = useState('');

    const [processing, setProcessing] = useState(false)
    const [cretingCart, setCretingCart] = useState(false)

    const [paymentModal, setPaymentModal] = useState(false)
    const [taxAmount, setTaxAmount] = useState(0)
    const [totalTaxedAmount, setTotalTaxedAmount] = useState(0)



    const virginiaTax = 5.3


  useEffect(()=>{
    const totalItemsCost = cart.cartTotalAmount 
    let tax = Math.round((virginiaTax * totalItemsCost)/100)
    setTaxAmount(tax)

    let totalTaxed = totalItemsCost + tax


    setTotalTaxedAmount(totalTaxed)


  },[cart.cartTotalAmount])  


  const scrollUP = () => {
    window.scrollTo(0, 0);
  }

    
    const handleDeliveryLacation = (place, lat, lng,locationPayload, dist, delivery) =>{
        setDeliveryLocation(place)
        setDeliveryLatitude(lat)
        setDeliveryLongitude(lng)
        setLocation(locationPayload)
        setDistance(dist)
        setDeliveryCost(delivery)


    }
  
useEffect(() =>{
    setCartId(userCart?.id)
    
},[userCart,createBasket])

useEffect(() =>{
  setOrderTotalAmount(totalTaxedAmount + deliveryCost)
  
},[deliveryCost,deliveryLatitude,deliveryLongitude,location,distance, taxAmount,totalTaxedAmount])

// console.log('cartId', cartId)
// console.log('distance', distance)


  
    const handlePayment = async () => {
      setProcessing(true)
      try {
        const response = await axios.post(`${baseUrl}/payment/create/`, {
          basket_id: cartId,
        });
        setPaymentId(response.data.payment_id);
        setProcessing(false)
        window.location.href = response.data.redirect_url;
      } catch (error) {
        console.log(error);
      }
    }
  
    const handleExecutePayment = async () => {
      try {
        const response = await axios.post(`${baseUrl}/payment/execute/`, {
          payment_id: paymentId,
          payer_id: payerId
        });
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    const handleDeleteBasket = async () => {
        try {
          const response = await axios.delete(`${baseUrl}/baskets/${cartId}/delete/`);
          console.log(response.data);
          setCartId('')
        } catch (error) {
          console.log(error);
        }
      }
  
    const handlePaymentAndExecute = async () => {
      scrollUP()
      if (paymentId) {
        await handleExecutePayment();
      } else {
        await handlePayment();
      }
    }
    const navigate = useNavigate()
    // const transferCart = useTransferCartFromLocalstorageToApiMutation()

    

    const handleAddToCart = (product) =>{
        dispatch(addToCart(product))

    }
    const handleRemoveFromCart = (product) => {
        dispatch(removeFromCart(product))
    }
    const handleDecreaseCartQuantity = (product) => {
        dispatch(decreaseCartQuantiy(product))
    }
    const handleClearCart = () => {
        dispatch(clearCart())
    }

    useEffect(() => {

        dispatch(getTotals())
    }, [cart, dispatch])

    const handleOrderCreate = async () => {
      if(token !== null){

        setCretingCart(true);
      
        const createBasketResponse = await createBasket({
          profile: userid,
          total: orderTotalAmount,
          delivery_latitude: deliveryLatitude,
          delivery_longitude: deliveryLongitude,
          delivery_place_name: deliveryLocation,
          delivery_payload: location,
          delivery_distance:distance,
          delivery_cost:deliveryCost,
          cart_total_amount:cart.cartTotalAmount,
          tax_amount:taxAmount,
          total_taxed_amount:totalTaxedAmount,


        });
      
        // console.log('createBasketResponse',createBasketResponse)
        const basketId = createBasketResponse.data.id;
        setCartId(basketId)
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      
        let completedMutationsCount = 0;
        const totalMutationsCount = cartItems.length;
      
        cartItems.forEach(async (cartItem) => {
          try {
            await transferCartFromLocalstorageToApi({
              basket: basketId,
              product: cartItem.id,
              quantity: cartItem.cartQuantity,
            });
            completedMutationsCount++;
            if (completedMutationsCount === totalMutationsCount) {
              scrollUP()
              setPaymentModal(true);
              setCretingCart(false);
            }
          } catch (error) {
            // console.log('error', error);
            toast.error(`Something went wrong`, {
              position: 'bottom-center',
            });
          }
        });
      } else{
        // setOpen(true)
        setLogin(true)
        setOpen(true)
        setReg(true)
      }
      };
      

    const handleCartTransfer = async () =>{
        setCretingCart(true)

        await createBasket({
            profile:userid, 
            total:cart.cartTotalAmount,
            delivery_latitude:deliveryLatitude,
            delivery_longitude:deliveryLongitude,
            delivery_place_name:deliveryLocation,
            delivery_payload:location,
        })
        
        const cartItems =  JSON.parse(localStorage.getItem('cartItems')) 

        await refetch();

        if(cartItems && cartItems.length > 0 ){
            cartItems.forEach(cartItem => {
                try{

                transferCartFromLocalstorageToApi({basket:userCart?.id, 
                    product: cartItem.id, 
                    quantity: cartItem.cartQuantity })

                }catch(error){
                    // console.log('error', error)
                    toast.error(`Something went wrong`,
                    {
                    position: "bottom-center",
                    })
                }   
            })

            setPaymentModal(true)
            setCretingCart(false)
        }

    }

    const handleClosePayement = () => {
        handleDeleteBasket()
        setPaymentModal(false)
        setCartId('')
    }

    // console.log('currentUseCart', userCart)
    // console.log('userid', userid)
    // console.log('deliveryLatitude',deliveryLatitude)
    // console.log('deliveryLongitude',deliveryLongitude)
    // console.log('deliveryLocation',deliveryLocation)
    // console.log('location',location)

    





  return (
    <>
            {/* {open && 
        <SignIn open={open}  handleClose={handleClose}/>
        } */}

{login &&
    <OnBoarding setReg={setReg} open={open} reg={reg} setOnBoarding={setLogin}/>
    }
            {/* <div className="absolute flex flex-col gap-2 top-0 left-0 w-full bg-opacity-75 h-screen bg-white  blur-sm z-50 flex justify-center items-center">
          <div className="animate-spin rounded-full z-10 h-16 w-16 border-t-2 border-b-2 border-[#FF6600]"></div>
          <p className='font-satoshi text-[28px] z-10'>Processing payment please wait</p>
        </div> */}
    {paymentModal &&
        <div
    
        // onClick={setPaymentModal(false)}
        className='absolute flex flex-col gap-2 top-0 left-0 w-full bg-opacity-75 h-screen bg-gray-800 z-50  justify-center items-center'>
        <div  className="w-[80%] md:w-[30%]  rounded-md flex flex-col  bg-white  gap-4 p-4">
        <div className="w-full flex flex-row-reverse">
            <svg
            onClick={handleClosePayement}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

            </div>
            <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row justify-between">

            <h1 className='font-satoshi text-[20px] font-semibold'>Pay with PayPal</h1>
            <h1 className='font-satoshi text-[22px] font-semibold'>${orderTotalAmount}</h1>

                </div>

                <button className='px-4 py-2 flex justify-center items-center rounded-md font-satoshi tex-[14px] bg-slate-700 text-white' onClick={handlePaymentAndExecute}>{processing? 
                                                        <ThreeDots 
                                                            height="30" 
                                                            width="80" 
                                                            radius="9"
                                                            color="#FF6600" 
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName="justify-center items-center flex"
                                                            visible={true}
                                                            /> 
                                                            :'Make Payment'}</button>
            </div>
    
    </div>
     
    </div>
    }
    <div className="flex flex-col-reverse md:flex-row gap-6 w-full h-full container mx-auto py-10 px-2">
    {cart.cartItems.length != 0 ? 
    <>
    <div className="flex flex-col gap-3 w-full md:w-[70%] h-full border rounded-md shadow-md">
        {cart.cartItems?.map((cartItem , index) => (

    <div key={cartItem.id} className="flex flex-col sm:flex-row  w-full gap-2 p-4 border-b border-gray-200">
        <div className="w-[10%]">
            <img src={cartItem.thumbnail_url} className='w-[4rem] md:w-[5rem] h-[4rem] md:h-[5rem]' alt="" />
        </div>
        <div className="flex flex-col flex-grow gap-1 justify-start items-start">
        <p className="font-satoshi text-gray-400 text-[14px] md:text-[16px] cursor-pointer ">
            {cartItem.category.name}
            </p>
        <p className="font-satoshi text-black text-[14px] md:text-[18px] cursor-pointer ">
            {cartItem.name}
            </p>

            <div
            onClick={() => handleRemoveFromCart(cartItem)}
             className="col-span-1 flex flex-row gap-1 cursor-pointer ">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-red-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
            <p
             className="font-satoshi  text-red-600 text-[14px] md:text-[16px]  ">
            Delete
            </p>
            </div>

        </div>
        <div className="flex w-full sm:w-[60%] md:w-[20%] flex-col gap-2 justify-start sm:justify-end items-start sm:items-end">
        <p className="font-satoshi text-black text-[14px] md:text-[16px] cursor-pointer font-semibold ">
            $ {cartItem.new_price}
            </p>

            <div className="flex flex-row gap-2 w-auto justify-center border rounded-sm shadow-md">
                    <div className="p-2 flex justify-center">
                    <svg 
                    onClick={() => handleDecreaseCartQuantity(cartItem)}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                    </svg>

                    </div>
                    <div className="border-r border-gray-200"></div>
                    <div className="p-2">
                    {cartItem.cartQuantity}
                    </div>
                    <div className="border-r border-gray-200"></div>

                    <div
                     className="p-2 flex justify-center cursor-pointer">
                    <svg
                    onClick={() => handleAddToCart(cartItem)}
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                        </svg>

                    </div>
                </div>
        </div>
    </div>

    ))}

        </div>
        <div className="flex flex-col gap-2 w-full md:w-[30%] border h-full p-2 shadow-md rounded-md">
            <div className="flex justify-end items-end">

            <button
            onClick={handleClearCart}
             className='flex flex-row gap-2 font-satoshi px-4 py-2 border border-red-400 bg-red-100 text-red-600 rounded-sm'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                </svg>

                Clear cart</button>
            </div>
           
                <div className="flex flex-col gap-2 h-full">
                  <div className="flex flex-row gap-3 w-full">
                <div className="flex flex-col gap-1">

                <p className="font-satoshi text-black text-[14px] md:text-[18px] font-medium ">
                    Cart total ({cart.cartTotalQuantity } items) : 
                    <span className='text-[#FF6600] ml-1 text-[20px] font-semibold'>${cart.cartTotalAmount}</span>

                    </p>
                    <p className="font-satoshi text-black text-[14px] md:text-[16px] font-medium ">
                    Tax : 
                    <span className='text-[#FF6600] ml-1 text-[14px] md:text-[18px] font-semibold'>${taxAmount}</span>

                    </p>
                    <p className="font-satoshi text-black text-[14px] md:text-[18px] font-medium ">
                    Total : 
                    <span className='text-[#FF6600] ml-1 text-[20px] font-semibold'>${orderTotalAmount}</span>

                    </p>
                </div>
                    <p className="font-satoshi text-black text-[12px] font-medium ">
                    + Delivery cost : 
                    <span className='text-[#FF6600] ml-1 text-[12px] font-semibold'>${deliveryCost}</span>

                    </p>
                   
                  </div>
                    <div className="flex flex-col gap-2">
                <GoogleMapInput handleDeliveryLacation={handleDeliveryLacation}/>
            </div>

                    <button
                    onClick={handleOrderCreate}
                     className={` font-satoshi flex justify-center items-center px-4 py-2 rounded-sm ${deliveryLocation !== null ? 'bg-[#FF6600] text-white':'bg-gray-200 pointer-events-none'}`}>
                        {cretingCart? <ThreeDots 
                        height="30" 
                        width="80" 
                        radius="9"
                        color="#ffffff" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName="justify-center items-center flex"
                        visible={true}
                        /> :'Checkout'}
                     </button>
                </div>


</div>

    </>
    :
        <>
        <div className="flex flex-col w-full justify-center items-center place-items-center h-full py-40 container mx-auto gap-4">
        <p className="font-satoshi text-center text-black text-[22px] font-medium cursor-pointer ">
                Your cart is empty
                </p>
                <Link to={ENDPOINT_PRODUCTS} >
                <button className='w-full font-satoshi px-4 py-2 rounded-sm bg-[#FFDFBF] flex flex-row gap-2 border text-[14px] md:text-[18px] border-[#FF6600] text-[#FF6600]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
    </svg>
    Go shopping</button>
                </Link>
        </div>
        </>
}
    </div>

        <>



    </>

    <ToastContainer />
    </>
  )
}

export default Cart