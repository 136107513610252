import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addToCart, decreaseCartQuantiy, getTotals, removeFromCart } from '../features/cart/cartSlice'
import { useGetSingleProductQuery } from '../features/products/productsApiSlice'
import { BsQuestionCircleFill } from "react-icons/bs";
import ModalLayout from './common/ModalLayout'
import PriceEnquiryModal from './common/PriceEnquiryModal'

function SingleProduct() {
    const [openModal, setOpenModal] = useState(false)

    const handleClose = () => {
      setOpenModal(false)
    }
    const { productID } = useParams()
    const { data: product } = useGetSingleProductQuery(productID)
    const [currentImage, setCurrentImage] = useState([])

    const cart = useSelector((state) => state.cart)


    useEffect(() => {
        setCurrentImage(product?.thumbnail_url)
        
    },[product])

    const dispatch = useDispatch()

    const handleAddToCart = (product) =>{
        dispatch(addToCart(product))

    }

    const handleDecreaseCartQuantity = (product) => {
        dispatch(decreaseCartQuantiy(product))

    }
    const qty = cart.cartItems?.filter((cartItem) => cartItem?.id === product?.id).map((cartItem) => cartItem?.cartQuantity)
    const intqty = qty[0]
    // console.log('qty', intqty)
    function generateRandomRating() {
        // Generate a random number between 0 and 1.5
        const randomValue = Math.random() * 1.5;
        // Add the base rating of 3.5
        const rating = 3.5 + randomValue;
        // Round the rating to one decimal place
        return rating.toFixed(1);
      }

  return (
    <>
    {openModal && <ModalLayout open={openModal} setToggleModal={handleClose} title="Enquire price">
      <PriceEnquiryModal product={product} handleClose={handleClose}/>
    </ModalLayout>}
    <div className='flex md:grid flex-col md:grid-cols-2 gap-2 bg-white h-full shadow-sm px-4 py-6'>
        <div className="col-span-1 flex flex-col gap-2 w-full h-full">
            <img src={currentImage?currentImage:product?.images_urls?product?.images_urls[0]:''} className='w-full h-96 object-contain border rounded p-1' alt="" />
            <div className="flex flex-row gap-2 overflow-x-scroll  hide-scrollbar">
                {product?.images_urls?.map((image) =>(

                <img 
                onClick={() => setCurrentImage(image)}
                key={image?.id} src={image} className='cursor-pointer border p-1 w-20 h-20 rounded' alt="" />
                ))}
              
                {currentImage !== product?.thumbnail_url?
                <img 
                onClick={() => setCurrentImage(product?.thumbnail_url)}
                key={product?.id} src={product?.thumbnail_url} className='cursor-pointer w-20 h-20 rounded' alt="" />
                :
                null
                }
            </div>
        </div>
        <div className="col-span-1 flex flex-col gap-4">
        <p className="font-satoshi text-gray-700 text-[16px] md:text-[20px] font-medium ">
            {product?.name}
        </p>
        <div className="border-b border-gray-200"></div>

        <div className="flex flex-row gap-2">
        <p className="font-satoshi text-[#FF6600] text-[16px] md:text-[20px] font-semibold ">
            Rating:  
            <span className='ml-2 font-satoshi text-gray-400 text-[16px] font-medium '>

            {generateRandomRating()}
            </span>
        </p>
            <div className="border-r  border-2 border-[#FF6600] ml-2 mr-2 h-4 mt-1"></div>
        <p className="font-satoshi text-[#FF6600] text-[16px] font-semibold ">
            Category: 
            <span className='ml-2 font-satoshi text-gray-400 text-[16px] font-medium '>

            {product?.category?.name}
            </span>
        </p>

        </div>
        <div className="bg-gray-100 rounded-sm px-2 py-3 gap-2 flex flex-col">
        {product?.old_price >0 &&<p className="font-satoshi line-through text-gray-400 text-[16px] font-medium ">
            ${product?.old_price}
        </p>}
        <div className="flex flex-row items-center gap-2">
        {!product?.enquire &&  <p className="font-satoshi  text-[#FF6600] text-[20px] font-semibold relative">
            ${product?.new_price}

        </p> }
            {(product?.old_price >0 && !product?.enquire )&&  <p className="font-satoshi rounded-sm px-4 py-0.5  bg-[#FF6600] text-white text-[12px] font-semibold -top-2 left-20 absolute">
            { Math.round(100 * ((product?.old_price - product?.new_price) / product?.old_price ))}% OFF
        </p>}

        </div>
        </div>
        {product?.details &&
        <div className="flex flex-col w-full gap-2">
        <p className="font-satoshi text-gray-700 text-[16px] md:text-[20px] font-medium ">
            Details
        </p>

                {product?.details?.map((detail) => (

        <p className="font-satoshi text-gray-400 text-[16px] flex flex-row gap-1 font-medium ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                </svg>

            {detail}
        </p>
        ))}
        </div>
        }
        {product?.enquire ? 
        <div onClick={() => setOpenModal(true)} className="">
            <p className='font-satoshi cursor-pointer text-gray-400 text-[14px] flex flex-row items-center gap-1'>Enquire price for this item
                <span>
                <BsQuestionCircleFill className='text-gray-300'/>
                </span>
            </p>
        </div>
        :
        <>
        {intqty > 0 ?
        <div className="flex flex-row gap-2 w-full items-center">
            <p className="font-satoshi text-gray-600 text-[16px] md:text-[20px] font-medium ">
                    Quantity :
                </p>
                <div className="flex flex-row gap-2 border rounded-sm">
                    <div
                     onClick={() => handleDecreaseCartQuantity(product)}
                    
                     className="p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                    </svg>

                    </div>
                    <div className="border-r border-gray-200"></div>
                    <div className="p-2">
                        {intqty || 0}
                    </div>
                    <div className="border-r border-gray-200"></div>

                    <div
                    onClick={() => handleAddToCart(product)}
                     className="p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                        </svg>

                    </div>
                </div>
                 
        </div>
        :
        <div 
        onClick={() => handleAddToCart(product)}
        className="flex flex-row gap-2 w-full cursor-pointer">
            <div className="flex flex-row gap-2 border w-[60%] bg-[#FDDFBF] md:w-[30%] justify-center border-[#FF6600] rounded-sm p-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-[#FF6600] w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                </svg>

            <p className="font-satoshi text-[#FF6600] text-[16px] font-medium ">
            Add to cart
                </p>
            </div>
        </div>}
        </>}
        </div>

    </div>
    </>
  )
}

export default SingleProduct
