import React from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_PRODUCTS } from '../../routes'

const CategoryCard = ({category}) => {
  return (
    <Link to={`${ENDPOINT_PRODUCTS}?cat=${category?.id}`} className="relative h-[6rem] min-w-[8rem] w-[8rem] rounded">
    {/* Image */}
    <img
      src={category?.thumbnail_url}
      alt={category?.name}
      className="w-full object-contain object-right items-end mx-auto h-full rounded overflow-hidden"
    />

    {/* Overlay */}
    <div className="absolute inset-0 bg-black opacity-60 rounded"></div>

    {/* Text */}

    <div className="absolute inset-0 flex flex-col justify-center items-center">
      
      <div className="flex flex-col justify-center items-center gap-4">
        {/* <p className='uppercase text-[14px] md:text-[18px] text-white md:text-gray-800 font-semibold'>uP to <span className='ml-1 text-[#FF780C]'>20% off</span></p> */}

      <div className="flex flex-col  w-full gap-1 justify-center items-center text-white ">
          <p className='font-semibold text-[16px] capitalize'>{category?.name}</p>
      </div>

      </div>
    </div>
  </Link>
  )
}

export default CategoryCard