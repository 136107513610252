import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetCurrentUserBasketsQuery } from '../features/auth/authenticationSlice'
import { ENDPOINT_ORDERS } from '../routes';
import Pagination from './Pagination';



function formatDate(date) {
  const now = new Date();
  const diff = now - date;

  // convert diff to days, hours, minutes
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  // return formatted date string
  if (days >= 30) {
    const months = Math.floor(days / 30);
    return ` ${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days >= 14) {
    return ` 2 weeks ago`;
  } else if (days >= 7) {
    return ` 1 week ago`;
  } else if (days >= 2) {
    return ` ${days} days ago`;
  } else if (days === 1) {
    return ` yesterday`;
  } else if (hours >= 6) {
    return ` ${hours} hours ago`;
  } else if (hours >= 1) {
    return ` ${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes >= 30) {
    return ` 30 minutes ago`;
  } else if (minutes >= 1) {
    return ` ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return ` just now`;
  }
}


function Orders() {

  const dispatch = useDispatch()
  
  const [page_size, setPage_size ] = useState(5)
  const [page, setPage ] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [prevPage, setPrevPage] = useState(1)
  const [open, setOPen] = useState([]);

  const {data:orders, isLoading} = useGetCurrentUserBasketsQuery({page:page}, { skip: !page })

  const toggleOpen = orderID => {
    const openState = open.slice()
    const index = openState.indexOf(orderID)
    
    if (index >= 0) {
      openState.splice(index, 1)
      setOPen(openState)
    } else {
      openState.push(orderID)
      setOPen(openState)
    }
  }

  const fetchNextPage = () => {
    setPage(orders?.next_page)

    }
  
    const fetchPreviousPage = () => {
    setPage(orders?.previous_page)
    }
  
    const fetchFirstPage = () => {
      setPage(1)    
      }
  
    const fetchLastPage = () => {
      setPage(orders?.total_pages)
  
          
        }

  useEffect(() => {

    setNextPage(orders?.next_page)
    setPrevPage(orders?.previous_page)
    setTotalPages(orders?.total_pages)
    setCurrentPage(orders?.current_page_number)
  },[orders])      

 
  // console.log('page',page)
  return (
    <>
    {isLoading? 
    <p>loading ....</p>
    :
    <>
    <div className="flex flex-col w-full gap-2">
    {orders?.results?.map((order) => (
      <div className='flex flex-col w-full'>
      <div className="flex flex-col gap-2 w-full rounded-md border shadow-sm px-4 py-2">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8 w-full">
          <div className="flex flex-col col-span-1 gap-2">

        <div className="font-satoshi text-[14px]">ORDER #{order.id}</div>
        <div className="font-satoshi text-[14px]">{order.lines?.length} {order.lines?.length ===1? 'Item':'Items'}</div>
          </div>
          <div className="flex flex-col col-span-1 gap-2">

            <div className="font-satoshi text-[14px]">Created {formatDate(new Date(order.updated_at))}</div>
            <div className="font-satoshi text-[14px]">{order.paid? 'Fully paid':'Partially paid'}</div>
              </div>

              <div className="flex flex-col col-span-1 justify-start md:justify-center item-start md:items-center  gap-2">
              

              <div className="grid grid-row-4 gap-1">
                <div className="grid grid-cols-2 gap-1">
                  <div className=" col-span-1 font-satoshi text-[14px] text-gray-500">Cart total </div>
                  <div className=" col-span-1 font-satoshi text-[14px]"> ${order?.cart_total_amount}</div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className=" col-span-1 font-satoshi text-[14px] text-gray-500">Order total  </div>
                  <div className=" col-span-1 font-satoshi text-[14px]"> ${order?.total}</div>
                </div>

                <div className="grid grid-cols-2 gap-1">
                  <div className=" col-span-1 font-satoshi text-[14px] text-gray-500">Delivery cost  </div>
                  <div className=" col-span-1 font-satoshi text-[14px]"> ${order?.delivery_cost}</div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className=" col-span-1 font-satoshi text-[14px] text-gray-500">Amount paid  </div>
                  <div className=" col-span-1 font-satoshi text-[14px]"> ${order?.amount_paid}</div>
                </div>

                {/* 5.3% tax */}

              </div>
                </div>
                <div className="flex flex-row-reverse col-span-1 gap-2 curso-pointer">
                  <div className="flex flex-row gap-1 font-satoshi text-[14px] flex justify-start md:justify-center item-start md:items-center cursor-pointer">
                    orders
                    {open.includes(order.id)?
                    <svg
                    onClick={() => setOPen([])}
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                :
                <svg
                onClick={() => toggleOpen(order.id)}
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
}
                  </div>





                  </div>
                  {order?.status === 'CLOSED' &&
                  <div className="flex col-span-1 justify-center items-center">
                    <Link to={`${ENDPOINT_ORDERS}/${order?.id}`}>
                    <button className='bg-gray-200 text-[14px] font-satoshi rounded-md px-4 py-1 text-center'>Track order</button>
                    </Link>
                  </div>
}

        </div>


      </div>

      <div className="flex flex-col gap-2 w-full">
      {open.includes(order.id) && (
        
        order?.lines?.map((line) => (

        <div className="flex flex-row w-full border rounded-md p-2 mt-2 gap-2">
          <div className="">
            <img src={line?.product?.thumbnail_url} alt="" className='w-[3rem] h-[4rem]' />
          </div>
          <div className="flex flex-col gap-2">
            <p className='font-satoshi text-[14px]'>{line?.product?.name}</p>
            <p className='font-satoshi text-[14px]'>${line?.product?.new_price}</p>


          </div>
          {/* {line.} */}
        </div>
        ))
        
        )}
      </div>
      </div>
       ))}
            <Pagination 
            currentPage={currentPage}
            totalPages={totalPages}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            fetchFirstPage={fetchFirstPage}
            fetchLastPage={fetchLastPage}/>
    </div>


  </>
    }
    </>
  )
}

export default Orders