import React from 'react'
import RevFooter from '../components/shared/RevFooter'
import TopBar from '../components/shared/TopBar'

function PrivacyPolicy() {
  return (
    <div className="flex flex-col w-full">
          <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>

    <div className='flex flex-col gap-8 pt-10 w-full'>
    <div
      className="h-full w-full flex flex-col  items-start">
      <div className="container mx-auto flex flex-row justify-between items-start">
        <div className='w-full md:w-[98%] h-full flex flex-col justify-center items-start'>
          <div 
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full '>
              <div className='w-full mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                  PRIVACY POLICY
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We respect your privacy and are committed to protecting it through our 
                compliance with this privacy policy (“Policy”). This Policy describes 
                the types of information we may collect from you or that you may provide 
                (“Personal Information”) on the enthusiastic-art.com website (“Website”), “Uzuri craft” 
                mobile application (“Mobile Application”), and any of their related products and services 
                (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, 
                and disclosing that Personal Information. It also describes the choices available to you regarding our use of your
                 Personal Information and how you can access and update it.
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                This Policy is a legally binding agreement between you (“User”, “you” or “your”) 
                and this Website operator and Mobile Application developer (“Operator”, “we”, “us” or “our”). 
                If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have
                 the authority to bind such an entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, 
                understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.                    </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-start md:items-end">
        <div className='w-full h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Automatic collection of information
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                  
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                When you open the Website or use the Mobile Application, our servers automatically record information that your browser or device sends. This data may include information such as your device’s IP address and location, browser and device name and version, operating system type and version, language preferences, the webpage you were visiting before you came to the Services, pages of the Services that you visit, the time spent on those pages, the information you search for on the Services, access times and dates, and other statistics.      
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Services. This statistical information is not otherwise aggregated in such a way that would identify any particular User of the system.                    </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Uzuri craft collects location data to enable other users to locate another user for the purposes of for example, finding a mechanic, calculating prices & mapping information.  Location data is collected even when the app is running in the background.
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Collection of personal information
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                You can access and use the Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Services, you may be asked to provide certain Personal Information (for example, your name,email address and location). Indication that the nature of usage is in the background by using one of the following phrases “background” / “when the app is closed” / “always in use” / “when the app is not in use”.We receive and store any information you knowingly provide to us when you create an account, publish content,  or fill any forms on the Services. When required, this information may include the following:
                </p>
                <ul>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Account details (such as user name, unique user ID, password, etc)
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>- Contact information (such as email address, phone number, etc)</li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>- Basic personal information (such as name, country of residence, etc)</li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>- Geolocation data of your device (such as latitude and longitude)</li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>- Certain features on the mobile device (such as contacts, calendar, gallery, etc)</li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>- Any other materials you willingly submit to us (such as articles, images, feedback, etc)</li>
                </ul>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Services. Users who are uncertain about what information is mandatory are welcome to contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Privacy of children
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Services, please contact us to request that we delete that child’s Personal Information from our Services.                  </p>

                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Use and processing of collected information                  </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.                  </p>
                
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We act in the capacity of a data processor in situations when you submit Personal Information through the Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                In order to make the Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                </p>
                <ul>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Create and manage user accounts
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Send product and service updates
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Respond to inquiries and offer support
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Request user feedback
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Improve user experience
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Enforce terms and conditions and policies
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Protect from abuse and malicious users
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Respond to legal requests and prevent harm
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  - Run and operate the Services
                  </li>


                </ul>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. We may also combine or aggregate some of your Personal Information in order to better serve you and to improve and update our Services.                  </p>
              <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
              Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
              </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Managing information
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so by contacting us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Disclosure of information
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Depending on the requested Services or as necessary to complete any transaction
                
                 or provide any Service you have requested, we may share your information with our affiliates, 
                 contracted companies, and service providers (collectively, “Service Providers”) we rely upon to 
                 assist in the operation of the Services available to you and whose privacy policies are consistent 
                 with ours or who agree to abide by our policies with respect to Personal Information. We will not 
                 share any personally identifiable information with third parties and will not share any information 
                 with unaffiliated third parties. </p>
                 <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                 Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.
                 </p>
                 <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                 We may also disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Retention of information                  </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We will retain and use your Personal Information for the period necessary to comply with our legal obligations, as long as your user account remains active, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.
                </p>
                 <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                 We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Cookies                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Our Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. If you choose to decline cookies, you will not be able to use and experience the features of the Services.
                </p>
                 <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>

                 We may use cookies to collect, store, and track information for security and personalization, to operate the Services, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Data analytics                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Our Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Do Not Track signals                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit internetcookies.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Social media features                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                Our Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Email marketing                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section. We will maintain the information sent via email in accordance with applicable laws and regulations.
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>

                In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the email is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us. However, you will continue to receive essential transactional emails.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Push notifications                  
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We offer push notifications to which you may also voluntarily subscribe at any time. To make sure push notifications reach the correct devices, we rely on a device token unique to your device which is issued by the operating system of your device. While it is possible to access a list of device tokens, they will not reveal your identity, your unique device ID, or your contact information to us. We will maintain the information sent via email in accordance with applicable laws and regulations. If, at any time, you wish to stop receiving push notifications, simply adjust your device settings accordingly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Links to other resources
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                The Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Services and to read the privacy statements of each and every resource that may collect Personal Information.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Information security
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.

                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                     Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.

                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                   As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Data breach
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                In the event we become aware that the security of the Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email, mail you a letter.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Changes and amendments
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page, post a notification within the Services, and send you an email to notify you. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.

                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different from what was stated at the time your Personal Information was collected.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Acceptance of this policy
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services. This privacy policy was created with the help of WebsitePolicies.com

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full  h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                Contacting us
                </span>
              </div>
              <div className="border border-b-gray-200 w-5/12"></div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:

                </p>
                <ul>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  https://uzuricraft.com/contact-us
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  uzuricraft@gmail.com
                  </li>
                  <li className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                  {/* West One Parkland`s 3rd Floor , Nairobi , Kenya */}
                  </li>

                </ul>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div
      className="h-full w-full flex flex-col justify-center items-start">
      <div className="container mx-auto relative flex flex-row justify-between items-end">
        <div className='w-full   h-full flex flex-col justify-center items-start'>
          <div
            className='bg-white rounded md:home-section-desc p-5  flex flex-row justify-end items-end z-10'
          >
            <div className='w-full  '>
              <div className='w-full mt-5 mb-5'>
                <span className=' font-bold font-satoshi uppercase text-[#456DE6]'>
                
                </span>
              </div>
              <div className='w-full mt-5 mb-5'>
                <h3 className='text-3xl md:text-5xl font-semibold text-[#05041F] font-satoshi'>
                </h3>
              </div>
              <div className='w-full'>
                <p className='mb-4 text-[#456DE6] font-semibold text-2xl font-satoshi'>
                </p>
                <p className='mb-5 text-[#343B4F] text-lg font-satoshi leading-10'>
                This document was last updated on December 19, 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <RevFooter/>
    </div>

  )
}

export default PrivacyPolicy