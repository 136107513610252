import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_CONTACT, ENDPOINT_HOMEPAGE, ENDPOINT_PRIVACY_POLICY, ENDPOINT_PRODUCTS, ENDPOINT_TERMS } from '../../routes'

function Footer() {
    const [showButton, setShowButton] = useState(false)
    const [subscribeEmail, setSubscribeEmail] = useState('')

    const handleChange = (e) => {
        setShowButton(true)
        setSubscribeEmail(e.target.value)

    }
  return (
    <>
    <div className={`bg-[#FF6600] w-full px-2 py-3 flex flex-col`}>
        <div className="container mx-auto w-full  text-whie font-satoshi">
            <div className="flex flex-col w-full">
                <div className="flex md:grid flex-col md:grid-cols-4 gap-4 w-full">
                    <div className="col-span-1">
                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        logo
                    </p>
                    </div>
                    <div className="col-span-1 flex flex-col">
                    <p className="font-satoshi text-white  py-2 text-[20px] underline underline-offset-4 cursor-pointer ">
                        Quick links
                    </p>
                    <Link to={ENDPOINT_HOMEPAGE}>
                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Home
                    </p>
                    </Link>
                    <Link to={ENDPOINT_PRODUCTS}>

                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Shop
                    </p>
                    </Link>
                    <Link to={ENDPOINT_CONTACT}>

                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Contact
                    </p>
                    </Link>
                    </div>
                    <div className="col-span-1 flex flex-col">
                    <p className="font-satoshi text-white  py-2 text-[20px] underline underline-offset-4 cursor-pointer ">
                        Company
                    </p>
                    <Link to={ENDPOINT_PRIVACY_POLICY}>
                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Privacy policy
                    </p>
                    </Link>
                    

                    <Link to={ENDPOINT_TERMS}>

                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Terms & conditions
                    </p>
                    </Link>

                    </div>
                    <div className="col-span-1 flex-col">
                    <p className="font-satoshi text-white  py-2 text-[20px] underline underline-offset-4 cursor-pointer ">
                        News letter
                    </p>
                    <p className="font-satoshi text-white  py-2 text-[16px] cursor-pointer ">
                        Subscribe to our news letter to get updates on new items and also offers.
                    </p>

                    <form action="" className='w-full'>
                        <div className="flex flex-row gap-1  w-full p-1">

                        <input
                        value={subscribeEmail}
                        onChange={(e) => handleChange(e)}
                         type="text" className='bg-white flex outline-none rounded-sm font-satoshi text-[16px] text-black px-4 py-2 w-full' />
                         {subscribeEmail.length > 0 &&
                         <button className='font-satoshi text-[14px] text-white text-center px-3 py-1 bg-black rounded-md'>Subscribe</button>
                        }   
                        </div>

                    </form>
                    </div>
                </div>
                <div className="border-b border-white w-full"></div>
                <p className="font-satoshi text-white text-center py-2 text-[16px] cursor-pointer ">
                &copy; {new Date().getFullYear()} Enthusiastic art. All rights reserved
                </p>
            </div>

        </div>
    </div>
    </>
  )
}

export default Footer