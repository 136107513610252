import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Success from '../components/Success';
import { useGetCurrentUserBasketQuery } from '../features/auth/authenticationSlice';
import { clearCart } from '../features/cart/cartSlice';
import { ENDPOINT_ACCOUNT } from '../routes';
import TopBar from '../components/shared/TopBar';
import RevFooter from '../components/shared/RevFooter';

function CheckoutPage() {
    const cart = useSelector((state) => state.cart)
    const { data:userCart, refetch } = useGetCurrentUserBasketQuery()
    // const cartID =  JSON.parse(localStorage.getItem('cartID')) 


    // const baseUrl = 'http://127.0.0.1:8000/api/v1' 
    const baseUrl = 'https://staging--api-enthusiastic.up.railway.app/api/v1' 

     

    const navigate = useNavigate()

    const [paymentId, setPaymentId] = useState(null);
    const [paying, setPaying] = useState(true)
    const [payerId, setPayerId] = useState(null);
    const dispatch = useDispatch()
  
    const handleExecutePayment = async () => {
    await refetch()
      setPaying(true)
      const searchParams = new URLSearchParams(window.location.search);
      if(userCart !== undefined){

          try {
            const response = await axios.post(`${baseUrl}/payment/execute/`, {
              payment_id: paymentId,
              payer_id: payerId,
              basket_id: userCart.id
            });
            // console.log(response.data);
            searchParams.delete("paymentId")
            searchParams.delete("PayerID")
            searchParams.delete("token")
            dispatch(clearCart())
    
    
            navigate(ENDPOINT_ACCOUNT)
            setPaying(false)
      
          } catch (error) {
            setPaying(false)
            console.log(error);
          }
      }
    }
  
  
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
  
        setPaymentId(searchParams.get("paymentId"))
        setPayerId(searchParams.get("PayerID"))
      }, []);
  
    useEffect(()=>{
      if(paymentId !== '' && payerId !== '' && userCart !== undefined){
        
        handleExecutePayment()
      }
  
    },[paymentId,payerId, userCart])

    // console.log('userCart',userCart)
  return (
    <>
    <div className="">
    <div className='w-full flex flex-col gap-2 bg-[#FFEFE4] bg-opacity-80 h-[15vh]'>
        <div className="container mx-auto w-full flex flex-col gap-6">
            <TopBar/>
        </div>
        
    </div>

              {!paying && (
        <div className="absolute flex flex-col gap-2 top-0 left-0 w-full bg-opacity-75 h-screen backdrop-blur-lg bg-white z-50 flex justify-center items-center">
          <div className="shadow-md border px-6 py-2 flex flex-col gap-2  jsutify-center items-center">

          <div className="animate-spin rounded-full z-10 h-16 w-16 border-t-2 border-b-2 border-[#FF6600]"></div>
          <p className='font-satoshi text-[28px] z-10 animate-bounce'>Processing payment please wait</p>
          </div>
        </div>
      )}
    {cart.cartItems.length === 0 &&
    <div className="w-full md:w-[60%] flex flex-col gap-2 justify-center items-center container mx-auto min-h-[60vh]">

        <div className="flex flex-col gap-2 w-full rounded border">
        {cart.cartItems?.map((cartItem , index) => (
        <>
    <div key={cartItem.id} className="flex flex-row gap-2 p-4">
        <div className="w-[10%]">
            <img src={cartItem.thumbnail_url} className='w-[5rem] h-[5rem]' alt="" />
        </div>
        <div className="flex flex-col flex-grow gap-1 justify-start items-start">
        <p className="font-satoshi text-gray-400 text-[13px] cursor-pointer ">
            {cartItem.category.name}
            </p>
        <p className="font-satoshi text-black text-[16px] cursor-pointer ">
            {cartItem.name}
            </p>
            <p className="font-satoshi text-black text-[16px] cursor-pointer ">
                    {cartItem.cartQuantity} {cartItem.cartQuantity === 1 ? 'Piece':'Pieces'} 

                    </p>



        </div>
        <div className="flex w-[20%] flex-col gap-2 justify-end items-end">
        <p className="font-satoshi text-black text-[16px] cursor-pointer font-semibold ">
            $ {cartItem.new_price}
            </p>


        </div>
    </div>
    <div className="border border-b w-full"></div>
        </>
    ))}

        </div>
    </div>
}
<RevFooter/>
    </div>

    </>
  )
}

export default CheckoutPage