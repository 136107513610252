import React from 'react'
import LanscapeProduct from './LanscapeProduct'
import { MdArrowRightAlt } from "react-icons/md"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom'
import { ENDPOINT_PRODUCTS } from '../../routes'

const AdvertGrid = ({products, loading}) => {
  
  return (
    <div className='container mx-auto w-full'>
        <div className="grid grid-cols-1 md:grid-cols-3 px-2  gap-5 w-full">
          {!loading ? 
            <div className="flex flex-col gap-2">
                  {products?.slice(0,3).map((product) => (
                <LanscapeProduct key={product?.id} product={product}/>
              ))}
            </div>
            :
            <div className="flex flex-col gap-2">
            <Skeleton height={100}/>
            <Skeleton height={100}/>
            <Skeleton height={100}/>
            </div>
            } 
            <div className="">
            <div className="relative h-full rounded">
      {/* Image */}
      <img
        src="https://png.pngtree.com/png-clipart/20230823/original/pngtree-circular-pattern-in-traditional-palestinian-style-picture-image_8269296.png"
        alt="Your Image"
        className="w-full md:w-auto h-full md:h-[20rem] mx-auto rounded overflow-hidden"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50 rounded"></div>

      {/* Text */}

      <div className="absolute inset-0 flex flex-col items-end">
        
        <div className="flex py-4">

        <div className="bg-black text-center px-3 py-1.5 text-white uppercase font-semibold">
            up to 30% off
        </div>
        </div>
        <div className="flex flex-col items-end w-full px-2 gap-1 text-white uppercase">
            <p className='font-semibold'>Contemporary</p>
            <p className='font-bold text-3xl'>Crafted art</p>
            <div className="">

            <Link to={ENDPOINT_PRODUCTS} className='bg-[#FF780C] px-3 rounded-md py-1.5 flex flex-row items-center gap-2 justify-center'>
                <p className='text-white text-[14px]'>Shop now</p>
                <MdArrowRightAlt className='text-white' size={20}/>
            </Link>
            </div>
        </div>
      </div>
    </div>
            </div>
            {!loading ? 
            <div className="flex flex-col gap-2">
              {products?.slice(3, products?.length).map((product) => (
                <LanscapeProduct key={product?.id} product={product}/>
              ))}
            </div>
            :
            <div className="flex flex-col gap-2">
            <Skeleton height={100}/>
            <Skeleton height={100}/>
            <Skeleton height={100}/>
            </div>
            } 
        </div>
    </div>
  )
}

export default AdvertGrid