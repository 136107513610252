import { createSlice } from '@reduxjs/toolkit';
// import jwt_decode from 'jsonwebtoken';




const authSlice = createSlice({
    name: 'auth',
    initialState:{
        user: localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')) 
        : null,
        userID: localStorage.getItem('userID')
        ? JSON.parse(localStorage.getItem('userID')) 
        : null,
        token: sessionStorage.getItem('accessToken')
        ? JSON.parse(sessionStorage.getItem('accessToken')) 
        : null,
        refreshToken: sessionStorage.getItem('refreshToken')
        ? JSON.parse(sessionStorage.getItem('refreshToken')) 
        : null,
        username: localStorage.getItem('username')
        ? JSON.parse(localStorage.getItem('username')) 
        : null,

        curentCart: sessionStorage.getItem('curentCart')
        ? JSON.parse(sessionStorage.getItem('curentCart')) 
        : null
    },
    reducers:{
        setCredentials:(state, action) => {
            // const { user, accessToken } = action.payload
            state.user = action.payload.username
            state.userID = action.payload.id
            state.token = action.payload.access
            state.refreshToken = action.payload.refresh
            state.username = action.payload.username
            state.firebaseToken = action.payload.firebase_custom_token

            
            // const decodedToken = jwt_decode(state.token)
            // const expiryDate = new Date(decodedToken.exp)
            // state.tokenExp = expiryDate
            // console.log('After login Payload', action.payload)
            sessionStorage.setItem('accessToken', JSON.stringify(state.token))
            sessionStorage.setItem('refreshToken', JSON.stringify(state.refreshToken))
            sessionStorage.setItem('firebaseToken', JSON.stringify(state.firebaseToken))

            localStorage.setItem('username', JSON.stringify(state.username))
            localStorage.setItem('user', JSON.stringify(state.user))
            localStorage.setItem('userID', JSON.stringify(state.userID))


            
        },
        setNewAccessToken:(state, action)=>{
            // state.token = action.payload.access
            // console.log('After token refresh Payload', action.payload)
            sessionStorage.setItem('accessToken', JSON.stringify(action.payload))
            sessionStorage.setItem('firebaseToken', JSON.stringify(state.firebaseToken))

            // const decodedToken = jwt_decode(action.payload)

            // const expiryDate = new Date(decodedToken.exp)
            // state.tokenExp = expiryDate
            // sessionStorage.setItem('tokenExp', JSON.stringify(state.tokenExp))


        },
        setCurrentCart:(state, action)=>{
            sessionStorage.setItem('curentCart', JSON.stringify(action.payload))

        },
        logOut: (state, action ) => {
            state.user = null
            state.userID = null
            state.token = null
            state.refreshToken = null
            state.username = null
            state.curentCart = null

            sessionStorage.setItem('accessToken', JSON.stringify(state.token))
            sessionStorage.setItem('refreshToken', JSON.stringify(state.refreshToken))
            sessionStorage.setItem('curentCart', JSON.stringify(state.curentCart))

            localStorage.setItem('username', JSON.stringify(state.username))
            sessionStorage.setItem('tokenExp', JSON.stringify(state.tokenExp))
            sessionStorage.setItem('firebaseToken', JSON.stringify(state.firebaseToken))


            localStorage.setItem('user', JSON.stringify(state.user))
            localStorage.setItem('userID', JSON.stringify(state.userID))
        }
    },
})

export const { setCredentials, logOut, setNewAccessToken, setCurrentCart} = authSlice.actions
export default authSlice.reducer

export const selectUser = (state) => state.auth.user
export const selectUserID = (state) => state.auth.userID

export const selectCurrentCart = (state) => state.auth.curentCart
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken

