import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { useRegisterMutation } from '../../features/auth/authenticationSlice';
import { setCredentials } from '../../features/auth/authSlice';

function Signup({open, setOnBoarding,setReg}) {
    const [show, setShow] = useState(open)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirm_password] = useState('')

    const [matchError, setMatchError] = useState("");

    const handleCloseSignup = () => {
      setOnBoarding(false)
    }
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      if (e.target.value !== confirm_password) {
        setMatchError("Passwords do not match");
      } else {
        setMatchError("");
      }
    };
  
    const handleConfirmPasswordChange = (e) => {
        setConfirm_password(e.target.value);
      if (e.target.value !== password) {
        setMatchError("Passwords do not match");
      } else {
        setMatchError("");
      }
    };

    const dispatch = useDispatch()

    const [ register, {isLoading} ] = useRegisterMutation()


    const handleSubmit = async (e) => {
        e.preventDefault();
        try{

            const userData = await register({ username,email, confirm_password, password }).unwrap()
            // console.log('userData', userData)
            // console.log('username to state', username)

            dispatch(setCredentials({ ...userData, username}))
            setEmail('')
            setPassword('')
            setUsername('')
            setConfirm_password('')
            toast.info(`Registration in successfully`,
            {
              position: "bottom-center",
            })
            console.log('welcome redirect')
            handleCloseSignup()
            // navigate(`${props.toPath}`)
        }catch (err) {
            if(!err?.originalStatus){

                toast.error(`${err.data.detail}`,
                {
                  position: "bottom-center",
                })
                console.log('NO server response',err)
            } else if (err?.originalStatus.status === 400){
                toast.error(`Missing username or password`,
                {
                  position: "bottom-center",
                })
                console.log('Missing username or password')
            } else if (err?.originalStatus.status === 401){
                toast.error(`You are unauthorized to this page`,
                {
                  position: "bottom-center",
                })
                console.log('Unauthorized')
            } else {
                toast.error(`Something went wrong`,
                {
                  position: "bottom-center",
                })
                console.log('Something went wrong', err)
            }

        }
    }

    const regRef = useRef()
    // useEffect(() => {
    //     let handler = e => {
    //       if (regRef.current && !regRef.current.contains(e.target)) {
    
    //         handleClose()
    
    //         console.log(regRef.current)
    //       }
    //     }
    //     document.addEventListener('mousedown', handler);
    
    //     return () => {
    //       document.removeEventListener('mousedown', handler);
    //     }
    
    //   }) 

      // redirect to account allTimes

    const close = () => {
        // setShow(false)
        handleCloseSignup()
    }  

    // console.log('open', open)
  return (
    <>
    {open &&
    <div
    
    // onClick={() => handleClose}
    className='fixed h-screen w-full flex justify-center items-center inset-0 bg-gray-900  bg-opacity-50 dark:bg-opacity-80 z-[100] px-2'>
    <div ref={regRef} className="w-full md:w-[30%]  rounded-md flex flex-col md:flex-row justify-center bg-white  gap-4 p-4">
        <form onSubmit={handleSubmit} action="" className='w-full h-full justify-center flex flex-col gap-2'>
            <div className="w-full flex flex-row-reverse">
            <svg
            onClick={close}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>

            </div>
            <div className="w-full flex flex-col gap-2">
                <p className='font-satoshi text-[24px] font-semibold'>Sign up</p>
                <p className='font-satoshi text-[16px] md:text-[18px]'>
                Please enter your details to proceed.
                </p>
                <p className='font-satoshi text-[16px] md:text-[18px]'>
                We've saved your progress
                </p>


            </div>
            <div className="w-full">
                <label htmlFor="email" className='font-satoshi text-[14px]'>Email</label>
                <input value={email} onChange={(e) =>setEmail(e.target.value)} type="text" name='email' className='border border-[#FFF2E9] w-full rounded-md outline-none px-4 py-2 font-satoshi text-[16px]'
                placeholder='Please enter email' />
            </div>
            <div className="w-full">
            <label htmlFor="uname" className='font-satoshi text-[14px]'>Username</label>
                <input value={username} onChange={(e) =>setUsername(e.target.value)} type="text" name='uname' className='border border-[#FFF2E9] w-full rounded-md outline-none px-4 py-2 font-satoshi text-[16px]'
                placeholder='Please create username' />
            </div>
            <div className="w-full">
            <label htmlFor="password" className='font-satoshi text-[14px]'>Password</label>
            <div className="flex flex-row w-full border border-[#FFF2E9] px-4 py-2 rounded-md ">

                <input value={password} onChange={handlePasswordChange} type={showPassword? "text":"password"} name='password' className=' w-full rounded-md outline-none bg-white  font-satoshi text-[16px]'
                placeholder='Please create password' />
                {showPassword?
                
                <svg
                onClick={() => setShowPassword(false)}
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                :

                <svg
                onClick={() => setShowPassword(true)}
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6  cursor-pointer">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
              }


            </div>
            </div>
            <div className="w-full">
            <label htmlFor="confirm_password" className='font-satoshi text-[14px]'>Confirm Password</label>
                        <div className="flex flex-row w-full border border-[#FFF2E9] px-4 py-2 rounded-md ">

                        <input value={confirm_password} onChange={handleConfirmPasswordChange} type={showConfirmPassword? "text":"password"} name='password' className=' w-full rounded-md outline-none bg-white  font-satoshi text-[16px]'
                        placeholder='Please confirm your password' />
                  

                        {showConfirmPassword?
                
                <svg
                onClick={() => setShowConfirmPassword(false)}
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                :

                <svg
                onClick={() => setShowConfirmPassword(true)}
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6  cursor-pointer">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
              </svg>
              }

                        </div>
            </div>
            <div className="w-full">
            {matchError && <p className='text-[12px] font-satoshi text-red-500'>{matchError}</p>}
            </div>
            <div className="w-full">
                <button onClick={handleSubmit} className={`${matchError? 'pointer-events-none':''} bg-indigo-400 font-satoshi text-white text-[16px] px-4 text-center py-2 w-full rounded-md`}>{isLoading?'Submitting...':'Sign Up'}</button>
            </div>
            <p className='font-satoshi cursor-pointer' onClick={() =>setReg(false)}>Already have an account ? <span className='text-indigo-400'>Sign In</span></p>
        </form>

</div>
 
</div>
}
    </>
  )
}

export default Signup