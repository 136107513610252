import { apiSlice } from "../../app/api/apiSlice";

export const categoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCategories: builder.query({
            query: () => '/categories',
            // providesTags:['Products']
        }),
        getSingleCategory: builder.query({
            query: (uid) => `/categories/${uid}`,
            // providesTags:['Products']

        }),

        getSingleCategoryProducts: builder.query({
            query: (uid) => `/category/${uid}/products`,
            // providesTags:['Products']

        }),
        editOrder: builder.mutation({
            query: ({statusData,id}) => ({
                url: `/basket/${id}/update/`,
                method: 'PUT',
                body: statusData,
              }),
        }),
      
        
    }),
    
})


export const { 
    useGetCategoriesQuery,
    useGetSingleCategoryQuery,
    useGetSingleCategoryProductsQuery,
    useEditOrderMutation,
    
} = categoriesApiSlice