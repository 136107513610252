import React, { useState } from 'react'
import { ENDPOINT_CONTACT, ENDPOINT_HOMEPAGE, ENDPOINT_PRIVACY_POLICY, ENDPOINT_PRODUCTS, ENDPOINT_TERMS } from '../../routes'
import { Link } from 'react-router-dom'

const RevFooter = () => {
    const [showButton, setShowButton] = useState(false)
    const [subscribeEmail, setSubscribeEmail] = useState('')

    const handleChange = (e) => {
        setShowButton(true)
        setSubscribeEmail(e.target.value)

    }
  return (
    <div className='w-full bg-[#FFEFE4] font-satoshi py-4'>
        <div className="container px-2 mx-auto w-full flex flex-col gap-3">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 border-b-2 py-3 border-indigo-300">
                {/* <p className="font-bold text-gray-800 text-2xl">Uzuricraft.</p> */}
            <img src="/cdn/images/sulogo.png" className="w-[9rem]" alt="" />


                    <div className="col-span-1 flex flex-col gap-2">
                    <p className="font-satoshi text-gray-800 text-[20px] underline underline-offset-4 cursor-pointer ">
                        Quick links
                    </p>
                    <Link to={ENDPOINT_HOMEPAGE}>
                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Home
                    </p>
                    </Link>
                    <Link to={ENDPOINT_PRODUCTS}>

                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Shop
                    </p>
                    </Link>
                    <Link to={ENDPOINT_CONTACT}>

                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Contact
                    </p>
                    </Link>
                    </div>
                    <div className="col-span-1 flex flex-col">
                    <p className="font-satoshi text-gray-800 text-[20px] underline underline-offset-4 cursor-pointer ">
                        Company
                    </p>
                    <Link to={ENDPOINT_PRIVACY_POLICY}>
                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Privacy policy
                    </p>
                    </Link>
                    

                    <Link to={ENDPOINT_TERMS}>

                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Terms & conditions
                    </p>
                    </Link>

                    </div>
                    <div className="col-span-1 flex-col">
                    <p className="font-satoshi text-gray-800 text-[20px] underline underline-offset-4 cursor-pointer ">
                        News letter
                    </p>
                    <p className="font-satoshi text-gray-800 text-[16px] cursor-pointer ">
                        Subscribe to our news letter to get updates on new items and also offers.
                    </p>

                    <form action="" className='w-full'>
                        <div className="flex flex-row gap-1  w-full p-1">

                        <input
                        value={subscribeEmail}
                        onChange={(e) => handleChange(e)}
                         type="text" className='bg-slate-300 px-2 flex outline-none rounded-sm font-satoshi text-[16px] text-black px- w-full py-2' />
                         {subscribeEmail.length > 0 &&
                         <button className='font-satoshi text-[14px] text-white text-center px-3 py-1 bg-indigo-400 rounded-md'>Subscribe</button>
                        }   
                        </div>

                    </form>
                    </div>
            </div>
            <p className="font-satoshi text-gray-500 text-cente text-[16px] cursor-pointer ">
                &copy; {new Date().getFullYear()} Uzuricraft. All rights reserved
                </p>
        </div>
    </div>
  )
}

export default RevFooter