import React from 'react'
import { useParams } from 'react-router-dom'
import HomeSidebar from '../components/HomeSidebar'
import ProductsWithoutResults from '../components/ProductsWithoutResults'
import { useGetSingleCategoryProductsQuery } from '../features/categories/categoriesApiSlice'

function CategoryProducts() {
    const { categoryID } = useParams()
    

    const { data:categoryproducts, isLoading } = useGetSingleCategoryProductsQuery(categoryID)
    
  return (
    <>
    
    <div className="w-full h-full  flex flex-col md:flex-row gap-4">
      <div className="w-full md:w-[20%]">
        <HomeSidebar showCategories={false}/>
      </div>
        <div className="w-full md:w-[80%] flex flex-col gap-4">


      <ProductsWithoutResults products={categoryproducts?.products} isLoading={isLoading}/>

        </div>
    
     
    </div>
    
    </>
  )
}

export default CategoryProducts