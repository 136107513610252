import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useGetCategoriesQuery } from '../features/categories/categoriesApiSlice'
import { ENDPOINT_CATEGORY } from '../routes';
import { Link } from 'react-router-dom'
import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import ReactSlider from 'react-slider'

function HomeSidebar({handlesortBy,handlesortPrice, showCategories,handleCat, cat}) {

    let currentUrlParams = new URLSearchParams(window.location.search)
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)

    const { data: categories } = useGetCategoriesQuery()
    const [showAll, setShowAll] = useState(false)
    const [ price, setPrice ] = useState('');
    // const [ minPrice, setMinPrice ] = useState(0)
    // const [ maxPrice, setMaxPrice ] = useState(0)
    const [ sortBy, setSortBy] = useState(currentUrlParams.has('s') ? currentUrlParams.get('s') :'')
    const [prices, setPrices] = useState([currentUrlParams.has('lp') ? currentUrlParams.get('lp') : 0,
    currentUrlParams.has('hp') ? currentUrlParams.get('hp') : 0])  

    

    const [ showFilters, setShowFilters] = useState(false)



    const handleInput = (e)=>{
        setPrice( e.target.value );
      }

    const handleSortLatest = () => {
            handlesortBy('date')
    }  

    const handleSortOldest = () => {
        handlesortBy('-date')
}  

    const handlePrice = () => {
        if(price === 'Lowest_price'){
            handlesortPrice('-price')

        }else if (price === 'Highest_price'){
            handlesortPrice('price')

        }else{
            handlesortPrice('')


        }
    }

    const handleFilters = () => {
        handlePrice()
        // handleSort()

    }

    const filterByPrice = () => {
        // setCurrentPage(1)
  
        // set search params
        // currentUrlParams.set('hp', prices[0])
        // currentUrlParams.set('lp', prices[1])
        // setUrlParams(currentUrlParams)
        handlesortPrice(prices[0], prices[1])
    }
    const sorting = (sort) => {
        handlesortBy(sort)
        // currentUrlParams.set('s', sort)
        // setUrlParams(currentUrlParams)
    }
    const categorySelect = (cat) => {
        currentUrlParams.delete('cat')
        handleCat(cat)
        // setUrlParams(currentUrlParams)
    }

    const handleClearCat = () => {
        handleCat('')

    }
    const handleClearSort = () => {
        handlesortBy('')

    }

    const handleClearPrices = () => {
        handlesortBy('')
        handlesortPrice("", "")


    }

    const handleClearALlFilters = () => {
        setPrice('')
        // setMinPrice(0)
        // setMaxPrice(0)
        setSortBy('')
    }  

    const handleMinPriceChange = (e) => {
        const minPrice = e.target.value;
        setPrices((prevPrices) => [parseInt(minPrice), prevPrices[1]]);
      };
    
      const handleMaxPriceChange = (e) => {
        const maxPrice = e.target.value;
        setPrices((prevPrices) => [prevPrices[0], parseInt(maxPrice)]);
      };
    // console.log(price)
  return (
    <div className='flex flex-col px-2 gap-4 w-full md:w-[20%]'>
        <div 
        onClick={() => setShowFilters(!showFilters)}
        className="flex flex-row gap-1  md:hidden bg-white shadow-md border items-center p-2 justify-between">
            <div className="flex flex-row items-center gap-2 ">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" w-6 h-6 mt-1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
            </svg>
            <p className="font-satoshi text-[13px] font-bold uppercase cursor-pointer mt-1">
            Filters
        </p>
            </div>
            <div className="">
                {showFilters ?
                <BiChevronUp size={25}/>
                :
                <BiChevronDown size={25}/>}
            </div>
        </div>
        
    <div className={`w-full bg-white font-satoshi border rounded-md  flex-col gap-1 px-2 py-4 shadow-md  ${showFilters? 'flex':'hidden md:flex'}  `}>
        <div className="flex flex-row justify-between items-center">

        <p className="font-satoshi text-[14px] uppercase cursor-pointer ">
            Categories
        </p>
        <p onClick={handleClearCat} className="font-satoshi text-gray-600 text-[14px]  cursor-pointer ">
            Clear
        </p>
        </div>
        {categories?.slice(0,3).map((category) => (
            // <Link to={`${ENDPOINT_CATEGORY}/${category.id}`}>
            <div onClick={() =>categorySelect(category.id)} key={category.id} className={` px-4 py-2 rounded-sm w-full flex flex-row gap-1 cursor-pointer hover:bg-[#F2EDEB] ${parseInt(cat)===category?.id ? 'bg-[#FFF2E9]':'bg-gray-100 '}`}>{category.name}</div>
            // </Link>
        ))}
        {showAll ?
        <>
        {categories?.slice(4, categories.length).map((category) => (
            // <Link to={`${ENDPOINT_CATEGORY}/${category.id}`}>
                <div onClick={() => categorySelect(category.id)} key={category.id} className={`bg-gray-100 px-4 py-2 rounded-sm w-full flex flex-row gap-1 cursor-pointer hover:bg-[#F2EDEB] ${parseInt(cat)===category?.id ? 'bg-[#FFF2E9]':'bg-gray-100 '}`}>{category.name}</div>
            // </Link>
        ))}
        </>
        :
        null
        }
        {categories?.length > 4 &&
         <p 
         onClick={() => setShowAll(!showAll)}
         className="font-satoshi text-[16px] cursor-pointer justify-end flex flex-row gap-1 text-[#FF6600] ">
            {showAll?
            <>
            Show less
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mt-1.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
            </svg>

            </>
            :
            <>
            Show all
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mt-1.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25" />
            </svg>

            </>
            }
        </p>
}
    </div>

<>
<div className={`w-full bg-white border font-satoshi rounded-md  flex-col gap-1 px-2 py-4 shadow-md  ${showFilters? 'flex':'hidden md:flex'}  `}>
        <div className="flex flex-row justify-between items-center">

        <p className="font-satoshi font-bold text-[14px] cursor-pointer uppercase">
        Sort by

        </p>
        <p onClick={handleClearSort} className="font-satoshi text-gray-600 text-[14px]  cursor-pointer ">
            Clear
        </p>
        </div>
        <div className="flex flex-col w-full gap-3">
            <div className="flex flex-row gap-2 items-center">
                <input onChange={() => sorting('latest')} type="radio" name='sort' className='outline-none w-6 h-6 rounded-md accent-[#4F46E5]' />
                <p>Latest</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
                <input onChange={() => sorting('oldest')} type="radio" name='sort' className='outline-none w-6 h-6 rounded-md accent-[#4F46E5]' />
                <p>Oldest</p>
            </div>
        </div>
        </div>

    <div className={`w-full bg-white border font-satoshi rounded-md  flex-col gap-1 px-2 py-4 shadow-md  ${showFilters? 'flex':'hidden md:flex'}  `}>
    <div className="flex flex-row justify-between items-center">
       
        <p className="font-satoshi font-bold text-[14px] cursor-pointer uppercase">
        Price
        </p>
        <p onClick={handleClearPrices} className="font-satoshi text-gray-600 text-[14px]  cursor-pointer ">
            Clear
        </p>
        </div>

        <div className="flex flex-col gap-2">

        <div className="flex flex-col w-full pt-2 pb-3">

<ReactSlider
className="horizontal-slider"
thumbClassName="example-thumb"
trackClassName="example-track"
value={prices}
max={1000}
min={0}
ariaLabel={['Lower thumb', 'Upper thumb']}
ariaValuetext={state => `Thumb value ${state.valueNow}`}
renderThumb={(props, state) => <div {...props}></div>}

onChange={(value, index) => setPrices(value)}
/>
</div>
                    <div className="flex flex-col md:flex-row gap-2">

                    <div className="flex flex-col ">
                    <div className="flex flex-row  shadow-sm">
                    <input onChange={handleMinPriceChange} value={prices[0]} type="number" className='w-full outline-none px-2 py-1.5 border rounded ' name="" id="" />
                    </div>
                    </div>
                    <div className="flex flex-col ">

                    
                    <div className="flex flex-row shadow-sm">

                        <input onChange={handleMaxPriceChange} value={prices[1]} type="number" className='w-full outline-none px-2 py-1.5 border rounded ' name="" id="" />
                    </div>
                    </div>
                    </div>


                    <button 
                    onClick={filterByPrice}
                    className='px-4 py-1.5 rounded text-center bg-[#FFF2E9]'>Apply</button>
                    
                </div>
        {/* </form> */}

        {/* <label htmlFor="" className='font-satoshi text-[14px] font-medium'>Price</label> */}
        {/* <input type="range" onInput={ handleInput } /> */}
        {/* <input id="default-range" min={0} value={price} max={10000} onInput={ handleInput } type="range" class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
            <p className="font-satoshi text-[16px] text-black px-4 py-2 rounded-sm border border-[#FF6600] ">
                ${price}
            </p>
        <div className="flex flex-row gap-1 w-full py-2">
            <input value={minPrice} 
            onChange={(e) => setMinPrice(e.target.value)}
            type="number" placeholder='min' className='w-6/12 rounded-sm px-4 py-2 font-satoshi text-[16px] outline-none border border-[#FF6600]' />
            <input value={maxPrice} 
            onChange={(e) => setMaxPrice(e.target.value)}
            type="number" placeholder='max' className='w-6/12 rounded-sm px-4 py-2 font-satoshi text-[16px] outline-none border border-[#FF6600]' />

        </div> */}
 
    </div>
    {/* <button
    onClick={handleFilters}
     className={` ${showFilters?'flex':'hidden md:flex'} text-white font-satoshi px-4 py-2 rounded-sm bg-indigo-500  `}>Filter</button> */}
{/* 
    <button
    onClick={handleClearALlFilters}
     className={`text-white font-satoshi px-4 py-2 rounded-sm bg-[#FF6600]  ${showFilters? 'flex':'hidden md:flex'}  `}>Clear filters</button> */}
</>


    </div>

  )
}

export default HomeSidebar