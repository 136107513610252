import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ENDPOINT_PRODUCTS } from '../../routes'
import { addToCart, decreaseCartQuantiy } from '../../features/cart/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { BsQuestionCircleFill } from "react-icons/bs";
import ModalLayout from '../common/ModalLayout'
import PriceEnquiryModal from '../common/PriceEnquiryModal'


const CarouselProduct = ({product}) => {
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
    const handleAddToCart = (product) =>{
        dispatch(addToCart(product))

    }

    const handleDecreaseCartQuantity = (product) => {
        dispatch(decreaseCartQuantiy(product))

    }
    const qty = cart.cartItems?.filter((cartItem) => cartItem?.id === product?.id).map((cartItem) => cartItem?.cartQuantity)
    const intqty = qty[0]

  const discount = Math.round(100 * ((product?.old_price - product?.new_price) / product?.old_price))
  return (
    <>
    {openModal && <ModalLayout open={openModal} setToggleModal={handleClose} title="Enquire price">
      <PriceEnquiryModal product={product} handleClose={handleClose}/>
    </ModalLayout>}
    <div  className='flex font-satoshi flex-col gap-2 p-2 rounded-md min-w-[15rem]  shadow-sm border bg-white bg-opacity-30 relative h-full'>
    {!product?.enquire &&<>
                    {product?.old_price > 0 ? 
            <div className="absolute top-0 rounded-tr-md right-0 alert_wrap bg-[#FF6600] text-white px-2 py-1 alert_wrap text-[13px]  font-satoshi">
                {discount + '% OFF'}
            </div>
            :
            null 
            } 
      </>}
       <Link to={`${ENDPOINT_PRODUCTS}/${product?.id}`}>

    <img src={product?.thumbnail_url}
      className="w-[9rem] h-[12rem] mx-auto"
     alt={product?.name} />
       </Link>
     <div className="flex flex-col w-full h-full">
      <Link to={`${ENDPOINT_PRODUCTS}?cat=${product?.category?.id}`} className="flex flex-row-reverse">

      <p className="text-end text-[11px] rounded-full px-4 text-gray-700 bg-[#FFF2E9]">{product?.category?.name}</p>
      </Link>

      <Link to={`${ENDPOINT_PRODUCTS}/${product?.id}`} className="text-gray-900 text-[18px]">{product?.name}</Link>

            <div className={`flex flex-col h-full w-full ${product?.enquire ? 'justify-end': 'justify-end items-end'} `}>

      <div className="flex flex-row items-center justify-between w-full">
      <div className="flex flex-row w-full items-center gap-2">
        {!product?.enquire &&<p className="text-red-600 font-semibold">${product?.new_price}</p>}
      {/* <p className="text-pink-500 line-through text-[14px]">$50</p> */}
      </div>
      {!product?.enquire &&<>
      {intqty > 0  ?
            <div className="flex flex-row items-center gap-2">
            <div onClick={() => handleDecreaseCartQuantity(product)} className="rounded-full bg-[#FF780C] text-white cursor-pointer p-2">
              <BiMinus />
            </div>
            <p className="text-gray-500">{intqty || 0}</p>
            <div onClick={() => handleAddToCart(product)} className="rounded-full bg-[#FF780C] text-white cursor-pointer p-2">
              <BiPlus />
            </div>
            </div>
      :
    <button onClick={() => handleAddToCart(product)} className='text-center w-full md:w-[80%] text-white rounded-md text-[14px] px-4 py-2 bg-[#FF780C]'>Add to cart</button>}
      </>}

      </div>
      {product?.enquire &&
        <div onClick={() => setOpenModal(true)} className="">
            <p  className='font-satoshi cursor-pointer text-gray-400 text-[14px] flex flex-row items-center gap-1'>Enquire product's price
                <span>
                <BsQuestionCircleFill className='text-gray-300'/>
                </span>
            </p>
        </div>}
            </div>
     </div>
</div>
    </>
  )
}

export default CarouselProduct
