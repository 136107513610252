import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut, setNewAccessToken } from '../../features/auth/authSlice'


const baseQuery = fetchBaseQuery({
    // baseUrl: 'https://staging--api-enthusiastic.up.railway.app/api/v1',
    baseUrl:'https://staging--api-enthusiastic.up.railway.app/api/v1',
    // baseUrl: 'http://127.0.0.1:8000/api/v1',
    // credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token
        if (token){
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers
    }

})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery (args, api, extraOptions)
    

    // console.log('error from api', result?.error?.status)

    if (result?.error?.status === 401){
        // console.log('Sending refresh token')

        // sending refresh token to get new access token
        const refreshResult = await baseQuery(
            {
              url: '/auth/token/refresh/',
              method: 'POST',
              body: {
                // send the refresh token in sessionStorage
                refresh:  JSON.parse(sessionStorage.getItem('refreshToken')),
              },
            },
            api,
            extraOptions
          )
        // console.log('refreshResult', refreshResult)
        // console.log('new access', refreshResult.data.access)

        if(refreshResult?.data){
            const user = api.getState().auth.user
            // store new token
            api.dispatch(setNewAccessToken(refreshResult.data.access))
            // retry the original query with new access token
            result = await baseQuery (args, api, extraOptions)
        }else{
            api.dispatch(logOut())
        }

    }
    return result
}

export const authApiSlice = createApi({
    baseQuery:baseQueryWithReauth,
    endpoints: builder => ({})
})



